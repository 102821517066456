import { lightColorByPartner } from 'helpers/getColorPartner';

export default {
  root: {
    backgroundColor: lightColorByPartner,
    color: '#425A70'
  },
  clickable: {
    '&:hover, &:focus, &:active': {
      backgroundColor: '#EDF0F2'
    }
  },
  deletable: {
    '&:focus': {
      backgroundColor: lightColorByPartner
    }
  },
  outlined: {
    '&:hover': {
      backgroundColor: lightColorByPartner
    }
  }
};
