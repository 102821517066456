const smallTransform = 'translate(12px, 10px) scale(0.75)';
const normalTransform = 'translate(12px, 16px) scale(1)';

export default {
  filled: {
    '&$shrink': {
      transform: smallTransform
    },
    '&$focused': {
      transform: smallTransform
    },
    transform: normalTransform
  }
};
