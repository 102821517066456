// Palette
import { neutralNr20 } from 'common/colors';

export default {
  root: {
    borderRadius: '3px',
    overflow: 'hidden'
  },
  colorPrimary: {
    backgroundColor: neutralNr20
  }
};
