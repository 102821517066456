import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  withStyles,
  Snackbar
} from '@material-ui/core';
import { Portlet } from 'components';
import PropTypes from 'prop-types';
import { CheckCircle } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import style from './style';
import { maskingNumber } from 'helpers/Utility';

const StepDisburse = ({ classes, custName, accountNumber }) => {
  const [accountIdCopied, setAccountIdCopied] = React.useState(false);

  const onClickCopy = () => {
    setAccountIdCopied(!accountIdCopied);
  };
  return (
    <>
      <Grid alignItems="center" container direction="row" justify="center">
        <Grid
          alignContent="space-between"
          alignItems="center"
          container
          direction="column"
          item
          justify="center"
          xs={2}
        >
          <Grid item xs={2}>
            <div className={classes.roundNumber}>
              <Typography align="center" className={classes.numberStyle}>
                1
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Divider
              className={classes.dividerVertical}
              orientation="vertical"
            />
          </Grid>
          <Grid item xs={2}>
            <div className={classes.roundNumber}>
              <Typography align="center" className={classes.numberStyle}>
                2
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="column"
          justify="center"
          xs={10}
        >
          <Grid item>
            <Typography
              className={classes.fontDisb}
              color="textPrimary"
              variant="caption"
            >
              Siapkan dana di rekening gaji sebesar tagihan dengan saldo
              mengendap sesuai jenis rekening.{' '}
            </Typography>
            <Portlet className={classes.portletStyle}>
              <Grid
                alignItems="center"
                container
                direction="column"
                justify="center"
              >
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  item
                  justify="space-evenly"
                >
                  <Grid item xs={5}>
                    <Typography color="textPrimary" variant="caption">
                      Bank Raya
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography color="textPrimary" variant="caption">
                      an. {custName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.portletDivider} item>
                  <Divider />
                </Grid>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  item
                  justify="space-evenly"
                >
                  <Grid item xs={5}>
                    <Typography color="textPrimary" variant="caption">
                      No. Rekening
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      className={classes.accountNoText}
                      color="textPrimary"
                      id="accountNo"
                      variant="caption"
                    >
                      <Grid
                        alignItems="center"
                        container
                        direction="row"
                        justify="space-around"
                      >
                        <Grid item>
                          <span id="accountId">
                            {maskingNumber(accountNumber)}
                          </span>
                        </Grid>
                        <Grid item>
                          <span
                            onClick={() => {
                              navigator.clipboard.writeText(accountNumber);
                              setAccountIdCopied(true);
                            }}
                          >
                            <img
                              className={classes.icon}
                              src="/images/assets/copy.svg"
                            />
                          </span>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Portlet>
          </Grid>
          <Grid className={classes.padDiv} item>
            <Typography
              className={classes.fontDisb}
              color="textPrimary"
              variant="caption"
            >
              Pinang Flexi akan melakukan autodebet (pembayaran otomatis) ke
              rekening gaji kamu.{' '}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        ContentProps={{
          className: classes.snackbarStyle
        }}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClickCopy}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        //   bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        message={
          <span className={classes.snackbarStyleMessage}>
            <CheckCircle />
            <Typography className={classes.snackText} variant="caption">
              Nomor Rekening Berhasil disalin
            </Typography>
          </span>
        }
        open={accountIdCopied}
      />
    </>
  );
};

StepDisburse.propTypes = {
  accountNumber: PropTypes.number,
  appStatus: PropTypes.string,
  custName: PropTypes.string,
  title: PropTypes.string
};

export default withStyles(style)(StepDisburse);
