export default theme => ({
  paperRoot: {
    background: '#FFF',
    borderRadius: '6px',
    padding: theme.spacing(3),
    border: 'none'
  },
  titleText: {
    fontFamily: 'Roboto',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '24px',
    marginBottom: '24px'
  },
  amountText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '21px',
    textAlign: 'right',
    color: theme.palette.primary.main
  },
  paperRootLate: {
    background: '#FFF',
    boxShadow: '2px 2px 12px rgba(242, 101, 124, 0.15)',
    border: '1px solid #D70C24',
    borderRadius: '6px 6px',
    padding: '1rem 1rem',
    marginTop: '2vh'
  }
});
