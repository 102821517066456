import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID_PINANG;

export const init = (option = {}) => {
  return ReactGA.initialize(String(TRACKING_ID), option);
};

/*
 **
 **  CATERGORY and ACTION are Required
 **
 */
export const sendEvent = payload => {
  return ReactGA.event(payload);
};

export const sendPageView = path => {
  ReactGA.set({
    page: path
  });
  ReactGA.pageview(path);
};

export const sendModalView = modalName => {
  ReactGA.modalview(modalName);
};

export const sendException = ({ desc, fatal }) => {
  ReactGA.exception({
    description: desc,
    fatal: fatal
  });
};

export const sendEventLogin = params => {
  sendEvent({
    category: 'Login',
    ...params
  });
};

export const sendEventRegist = params => {
  sendEvent({
    category: 'Register',
    ...params
  });
};

export const SendEventLoanApply = params => {
  sendEvent({
    category: 'Loan Apply',
    label: params.isSecondApply === 'Y' ? '2nd Loan' : '1st Loan',
    ...params
  });
};

export const SendEventDisbursement = params => {
  sendEvent({
    category: 'Disbursement',
    label: params.isSecondApply === 'Y' ? '2nd Loan' : '1st Loan',
    ...params
  });
};

export const SendEventRepayment = params => {
  sendEvent({
    category: 'Full Advance Repayment',
    ...params
  });
};

export default {
  init,
  sendEvent,
  sendPageView,
  sendModalView,
  sendException,
  SendEventLoanApply,
  SendEventRepayment,
  sendEventLogin,
  sendEventRegist
};
