import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  frame: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px 0 0 0'
  },
  frameTwo: {
    backgroundColor: theme.palette.common.primaryPr20
  },
  frameThree: {
    backgroundColor: theme.palette.common.primaryPr20,
    borderRadius: '0 8px 0 0'
  }
}));
