import React from 'react';
import PropTypes from 'prop-types';

import Paper from '../Paper';
import { withStyles, Grid, Typography, IconButton } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import styles from './styles';

const ListMenu = ({ classes, children, icon, handler }) => {
  return (
    <>
      <Paper className={classes.menu} elevation={0} outlined squared={false}>
        <Grid
          alignContent="center"
          container
          direction="row"
          justify="space-evenly"
        >
          <Grid
            alignContent="center"
            container
            direction="row"
            item
            justify="flex-start"
            spacing={4}
            xs={11}
          >
            <Grid item xs={1}>
              {icon}
            </Grid>
            <Grid item>
              <Typography className={classes.title} variant="subtitle2">
                {children}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handler}>
              <ArrowForwardIosRoundedIcon /*style={{fill: '#444'}}*/ />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

ListMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handler: PropTypes.func,
  icon: PropTypes.object
};

export default withStyles(styles)(ListMenu);
