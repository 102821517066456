import axios from 'axios';
import { AUTHENTICATE_BASE_URL_GOLANG } from 'common/config';

const instance = axios.create({
  baseURL: AUTHENTICATE_BASE_URL_GOLANG,
  timeout: 60000
  //headers: { 'Access-Control-Allow-Origin': '*' }
});

export default instance;
