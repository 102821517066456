/* eslint-disable react/no-multi-comp */
import React from 'react';

import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';

const styles = theme => ({
  rootTab: {
    backgroundColor: theme.palette.common.primary,
    borderRadius: '5px',
    color: theme.palette.text.secondary,
    marginTop: '24px'
  },
  tabs: {
    // flexGrow: 1,
    height: '40px',
    minHeight: '40px',
    borderRadius: '5px',
    margin: '5px'
    // backgroundColor: '#F7F7F7 !important',
    //marginTop:'40px'
  },
  selected: {
    // margin: "5px",
    backgroundColor: 'white !important'
  },
  root: {
    // flexGrow: 1,
    // display: 'flex',
    alignItems: 'center'
  },
  appbar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none'
  },
  appbarTransparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 18
  },
  titleCenter: {
    flexGrow: 1,
    textAlign: 'center',
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 18
  },
  toolbar: {
    width: '100%'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      aria-labelledby={`nav-tab-${index}`}
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Container>{children}</Container>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      style={{
        backgroundColor: '#E6EEF5'
      }}
      {...props}
    />
  );
}

const MainAppBar = props => {
  const { classes, title, icon, onClick, history, variant, titleAlign, items } =
    props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (props.onChange) props.onChange(event, newValue);
  };

  const onBack = () => {
    history.goBack();
  };

  const panel = Object.keys(items).map(i => (
    <TabPanel index={items[i].id} key={items[i].id} value={value}>
      {items[i].content}
    </TabPanel>
  ));

  const tabs = Object.keys(props.items).map(i => (
    <LinkTab
      classes={{
        root: classes.tabs,
        selected: classes.selected
      }}
      key={props.items[i].id}
      label={props.items[i].label}
      {...a11yProps(props.items[i].id)}
    />
  ));

  return (
    <div className={classes.root}>
      <AppBar
        className={
          variant === 'transparent' ? classes.appbarTransparent : classes.appbar
        }
      >
        <Toolbar className={classes.toolbar}>
          {icon !== 'hide' && (
            <IconButton
              aria-label="menu"
              className={classes.menuButton}
              color={variant === 'transparent' ? 'default' : 'inherit'}
              edge="start"
              onClick={() => onClick || onBack()}
            >
              {icon}
            </IconButton>
          )}
          <Typography
            className={
              titleAlign === 'center' ? classes.titleCenter : classes.title
            }
            variant="h6"
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        <Tabs
          aria-label="nav tabs example"
          centered
          classes={{
            root: classes.rootTab
          }}
          indicatorColor={'white'}
          onChange={handleChange}
          value={value}
          variant="fullWidth"
        >
          {tabs}
        </Tabs>
      </Container>
      {panel}
    </div>
  );
};

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  titleAlign: PropTypes.oneOf(['left', 'center']),
  variant: PropTypes.string
};

MainAppBar.defaultProps = {
  title: '',
  icon: <ArrowBackIcon />,
  titleAlign: 'left'
};

export default withStyles(styles)(MainAppBar);
