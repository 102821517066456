import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'helpers/Utility';

const initialState = {
  messageModel: '',
  paymentInquiry: '',
  statusPayment: '',
  paymentStatus: localStorage.getItem('paymentStatus')
    ? localStorage.getItem('paymentStatus')
    : '',
  paymentTransac: '',
  lastPayment: localStorage.getItem('lastPayment')
    ? localStorage.getItem('lastPayment')
    : ''
};

const setFetchMessage = (state, action) => {
  return updateObject(state, { messageModel: action.data });
};

const setPaymentInquiry = (state, action) => {
  return updateObject(state, {
    paymentInquiry: action.data,
    statusPayment: action.status
  });
};

const setPaymentTrasaction = (state, action) => {
  return updateObject(state, { paymentTransac: action.data });
};

const setPaymentStatus = (state, action) => {
  localStorage.setItem('paymentStatus', action.val);
  return updateObject(state, { paymentStatus: action.val });
};

const setLastPayment = (state, action) => {
  localStorage.setItem('lastPayment', action.val);
  return updateObject(state, { lastPayment: action.val });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FECT_MESSAGE_PAYMENT:
      return setFetchMessage(state, action);
    case actionTypes.PAYMENT_INQUIRY:
      return setPaymentInquiry(state, action);
    case actionTypes.PAYMENT_TRANSACTION:
      return setPaymentTrasaction(state, action);
    case actionTypes.STATUS_PAYMENT:
      return setPaymentStatus(state, action);
    case actionTypes.LAST_PAYMENT:
      return setLastPayment(state, action);
    default:
      return state;
  }
};

export default reducer;
