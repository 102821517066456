import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Material UI Components
import Paper from '@material-ui/core/Paper';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const styles = theme => {
  return {
    root: {
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.greyBorder}`,
      borderRadius: theme.spacing(1),
      boxShadow: `0px 0px 1px ${theme.palette.surface.greyshadowElevation}, 
        0px 2px 4px ${theme.palette.surface.greyshadowSurface}`
    }
  };
};

const Surface = ({ children, className, classes, ...rest }) => {
  const rootClassName = classNames(
    {
      [classes.root]: true
    },
    className
  );
  return (
    <Paper
      className={rootClassName}
      elevation={2}
      variant="elevation"
      {...rest}
    >
      {children}
    </Paper>
  );
};

Surface.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default withStyles(styles)(Surface);
