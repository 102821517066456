import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { Typography, withStyles } from '@material-ui/core';

// Shared components
import Paper from '../Paper';

// Material components
import { Info as InfoIcon } from '@material-ui/icons';

// Component styles
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: '#b6b6b6',
    padding: 16
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: 12
  },
  iconGrid: {},
  text: {
    color: '#777',
    fontSize: 11,
    textAlign: 'start'
  },
  textGrid: {
    flex: 1,
    justifyContent: 'flex-start'
  }
});

const Portlet = props => {
  const { classes, className, text, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Paper
      {...rest}
      className={rootClassName}
      elevation={0}
      outlined
      squared={false}
    >
      <div className={classes.iconGrid}>
        <InfoIcon className={classes.icon} />
      </div>
      <div className={classes.textGrid}>
        <Typography className={classes.text} variant="body2">
          {text}
        </Typography>
      </div>
    </Paper>
  );
};

Portlet.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Portlet);
