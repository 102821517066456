import { AUTH_MODE, RESET_PASSWORD } from '../../common/config';
import axiosGolang from 'lib/axios/axios-golang';
import * as actionTypes from '../actions/actionTypes';
import { setError } from './auth';
import {
  getChannelContextCode,
  getFirstAuthorizationMode
} from '../../helpers/Utility';

const loading = val => {
  return {
    type: actionTypes.APP_LOADING,
    loading: val
  };
};

/**
 * @param {Object} consist of:
 * 1. requestBody
 * 2. callback
 */
export const submitNewPin = param => {
  const { callback, typedPin } = param;

  return (dispatch, getState) => {
    dispatch(loading(true));

    const requestHeader = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    const requestBody = {
      loginUserId: getState().auth.phoneNo,
      passwordDetails: {
        passwordType: AUTH_MODE,
        reTypesignOnPassword: typedPin,
        signOnPassword: typedPin
      }
    };

    axiosGolang
      .post(RESET_PASSWORD, requestBody, requestHeader)
      .then(() => {
        // IDK why it return 401 event though all ok
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        // Pin repeated ie 123456
        if (getChannelContextCode(err, 'channel-context') === '100473') {
          callback && callback('repeated');
          return;
        }

        // There's no response from server
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '200604') {
          dispatch(setError('contact-cs'));
          return;
        }

        // New PIN accepted (before LOAN_CREATED)
        if (getFirstAuthorizationMode(err, 'channel-context') === 'CLGOTP') {
          callback && callback('input-otp');
          return;
        }

        // New PIN accepted (status LOAN_CREATED)
        if (getFirstAuthorizationMode(err, 'channel-context') === 'CLGDC') {
          callback && callback('atm-verification');
          return;
        }

        setError('system');
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

/**
 * @param {Object} consist of:
 * 1. requestBody
 * 2. callback
 */
export const submitVerification = param => {
  const { requestBody, callback, failed } = param;

  return (dispatch, getState) => {
    dispatch(loading(true));

    const requestHeader = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .post(RESET_PASSWORD, requestBody, requestHeader)
      .then(res => {
        return res.status.toString().charAt(0) === '2';
      })
      .then(ok => {
        ok && callback && callback('ok');
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        // There's no response from server
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        // PIN already used before (at least latest 3 times)
        if (getChannelContextCode(err, 'channel-context') === '107302') {
          callback && callback('used');
          return;
        }

        // OTP doesn't match at all
        if (getChannelContextCode(err, 'channel-context') === '100879') {
          dispatch(setError('incorrect_otp'));
          failed && failed();
          return;
        }

        //  No record found for the entered information (via ATM verification)
        if (getChannelContextCode(err, 'channel-context') === '211051') {
          callback && callback('not-verified');
          return;
        }
        // Other(s) error
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const setForgotPinNewPinAlreadyUsed = isNewPinAlreadyUsed => {
  return {
    type: actionTypes.SET_FORGOT_PIN_NEW_PIN_ALREADY_USED,
    isNewPinAlreadyUsed: isNewPinAlreadyUsed
  };
};

export const setReTypeSignOnPassword = reTypeSignOnPassword => {
  return {
    type: actionTypes.SET_RETYPE_SIGN_ON_PIN,
    reTypeSignOnPassword: reTypeSignOnPassword
  };
};

export const setSignOnPassword = signOnPassword => {
  return {
    type: actionTypes.SET_TYPE_SIGN_ON_PIN,
    signOnPassword: signOnPassword
  };
};
