import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import {
  Checkbox,
  Typography,
  withStyles,
  Divider,
  Grid
} from '@material-ui/core';

// Shared components
import Paper from '../Paper';

// Component styles
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: '#b6b6b6',
    marginBottom: '3vh'
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: 12
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center'
  },
  textBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center'
  },
  titleBox: {
    padding: '10px 0px 0px 12px'
  },
  textTitle: {
    fontSize: 18,
    textAlign: 'start',
    fontWeight: 450,
    fontFamily: 'Roboto'
  },
  captionBox: {
    padding: '0px 0px 2px 10px'
  },
  textCaption: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500
  },
  textAmount: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#56a84d'
  },
  textGrid: {
    flex: 1,
    justifyContent: 'space-between',
    alignContent: 'center'
  }
});

const Portlet = props => {
  const { classes, className, text, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Paper
      {...rest}
      className={rootClassName}
      elevation={0}
      outlined
      squared={false}
    >
      <Grid
        alignContent="center"
        container
        direction="row"
        justify="space-between"
      >
        <Grid className={classes.titleBox} item>
          <Typography className={classes.textTitle}>{text}</Typography>
        </Grid>
        <Grid item>
          <Checkbox
            checked={props.checked}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: '1vh 0' }} />
      <div className={classes.captionBox}>
        <Typography className={classes.textCaption}>
          {props.caption}
          <span className={classes.textAmount}>{props.amount}</span>
        </Typography>
      </div>
    </Paper>
  );
};

Portlet.propTypes = {
  className: PropTypes.string
};

export default withStyles(styles)(Portlet);
