export default theme => ({
  paper: {
    backgroundColor: theme.palette.common.neutralNr20,
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    borderRadius: '6px',
    boxShadow: ' 0 4px 15px 4px rgba(51,51,51,0.1)'
  },
  paperTextBold: {
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  paperTextPriceBold: {
    fontWeight: 700,
    color: theme.palette.common.orange
  },
  divPaper: {
    backgroundColor: theme.palette.common.white
  },
  smallLatoDetailBold: {
    fontWeight: 700,
    fontFamily: 'Lato',
    color: theme.palette.info.main
  },
  divider: {
    backgroundColor: theme.palette.common.neutralNr30,
    width: 'auto',
    height: '0.1px',
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: '16px',
    marginRight: '16px'
  },
  successMessageBold2: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '25px',
    textAlign: 'left',
    marginTop: '8px',
    marginLeft: '16px',
    marginBottom: '8px'
  },
  infoHeight: {
    height: '35px'
  },
  isDisbursementText: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  grid3: {
    padding: '8px 24px'
  }
});
