import * as actionTypes from './actionTypes';
import axiosGolang from 'lib/axios/axios-golang';
import { USERS, LOANS, PAYMENTS } from '../../common/config';
import { setError } from './auth';
import { getChannelContextCode } from '../../helpers/Utility';

const loading = val => {
  return {
    type: actionTypes.APP_LOADING,
    loading: val
  };
};

/**
 * Fetch list of loan
 * @param params {Object} consist of token {String}, phoneNo {String} also
 * applicationStatus {String}
 * @param callback {function(*)}
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchLoans = (params, callback) => {
  const { token, accountId } = params;

  const header = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  };

  const url = USERS + LOANS + (accountId ? '/' + accountId : '');

  return dispatch => {
    dispatch(loading(true));

    return axiosGolang
      .get(url, header)
      .then(res => {
        if (!res.status || !res.data || !(res.data.data || res.data.list)) {
          dispatch(setError('system'));
          return;
        }

        const responseCodePrefix = res.status.toString().charAt(0);

        if (responseCodePrefix === '2' && res.data.data) {
          callback && callback(res.data.data);
          return;
        }

        if (responseCodePrefix === '2' && res.data.list) {
          callback && callback(res.data.list);
          return;
        }

        dispatch(setError('system'));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        // No Repayment Schedule Found for account
        if (
          getChannelContextCode(err, 'channel-context') === '8504' ||
          getChannelContextCode(err, 'channel-context') === '200606'
        ) {
          callback && callback([]);
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchLoanHist = (params, callback) => {
  const { token, accountId } = params;
  const finalPath = '/custom/loans?accountId=' + accountId;

  const header = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  };

  const url = USERS + finalPath;

  return dispatch => {
    dispatch(loading(true));
    return axiosGolang
      .get(url, header)
      .then(res => {
        if (!res.status || !res.data || !res.data.data) {
          dispatch(setError('system'));
          dispatch(loading(false));
          return;
        }

        if (res.status.toString().charAt(0) === '2') {
          callback && callback(res.data.data[0]);
          return;
        }

        dispatch(setError('system'));
        dispatch(loading(false));
      })
      .catch(err => {
        dispatch(loading(false));

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err) === '8504') {
          callback && callback([]);
          return;
        }

        dispatch(setError('system'));
      });
  };
};

/**
 * Fetch list of payment accountId
 * @param params {Object} consist of token {String}, phoneNo {String} and
 * accountId {Number}
 * @param callback {function(*)}
 * @returns {function(*): Promise<unknown>}
 */
export const fetchPayments = (params, callback) => {
  const { token, accountId } = params;
  const finalPath = PAYMENTS + accountId;

  const header = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  };

  const url = USERS + finalPath;

  return dispatch => {
    dispatch(loading(true));
    return axiosGolang
      .get(url, header)
      .then(res => {
        if (!res.status || !res.data || !res.data.data && !res.data.list) {
          dispatch(setError('system'));
          return;
        }

        if (res.status.toString().charAt(0) === '2') {
          if(res.data.data){
            callback && callback(res.data.data);
          }else{
            callback && callback(res.data.list);
          }
          return;
        }

        dispatch(setError('system'));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '8504') {
          callback && callback([]);
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '8506') {
          callback && callback([]);
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const goToHistoryPage = props => {
  const { applicationStatus } = props;

  return dispatch => {
    const params = {
      token: props.token,
      phoneNo: localStorage.getItem('phonePrincipal') || props.phoneNo
    };

    const fetchLoansCallback = loans => {
      const activeLoan = getActiveLoan({ loans, applicationStatus });
      params.accountId = activeLoan.accountId;
      dispatch(setLoans(loans));

      // There's no active payment
      if (!params.accountId) {
        dispatch(setPayments([]));
        return;
      }

      // Fetch active payment based on accountId
    };

    // Fetch all list loans already exist
    dispatch(fetchLoans(params, fetchLoansCallback));
  };
};

const getActiveLoan = params => {
  const { loans, applicationStatus } = params;

  if (!loans || !Array.isArray(loans) || loans.length < 1) {
    return {};
  }

  const selectedLoans = loans.filter(loan => {
    if (applicationStatus === 'LOAN_CREATED' && loan.accountStatus) {
      return loan.accountStatus.cmCode === 'A';
    }

    if (applicationStatus !== 'LOAN_CREATED' && loan.accountStatus) {
      return loan.accountStatus.cmCode === 'P';
    }

    return {};
  });

  const activeLoan = selectedLoans.length > 0 ? selectedLoans[0] : {};

  return activeLoan;
};

/**
 * Group payment by accountId
 * @param params
 * @returns {{accountId: string, payments: *, loanSrlNum: *}}
 */

export const setPayments = data => {
  return {
    type: actionTypes.SET_HISTORY_PAYMENTS,
    payments: data
  };
};

export const setLoans = data => {
  return {
    type: actionTypes.SET_HISTORY_LOANS,
    loans: data
  };
};
