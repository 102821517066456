export const SET_ERROR = 'SET_ERROR';
export const APP_LOADING = 'APP_LOADING';

//auth
export const SET_APP_TNC = 'SET_APP_TNC';
export const SET_NEW_PIN = 'SET_NEW_PIN';
export const SET_SPLASH_SCREEN = 'SET_SPLASH_SCREEN';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_PHONE_NUMBER_RAYA = 'SET_PHONE_NUMBER_RAYA';
export const SET_PHONE_NUMBER_FLEXI_NEW = 'SET_PHONE_NUMBER_FLEXI_NEW';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_OAUTH_TOKEN = 'SET_OAUTH_TOKEN';
export const SET_REG_PIN = 'SET_REG_PIN';
export const TYPING_PIN = 'TYPING_PIN';
export const DEL_PIN = 'DEL_PIN';
export const RESET_TYPED_PIN = 'RESET_TYPED_PIN';
export const INPUT_OTP = 'INPUT_OTP';
export const SET_HOME_PATH = 'SET_HOME_PATH';
export const SET_IS_LOGIN = 'SET_IS_LOGIN';
export const SET_PARAMETER_ACCESS = 'SET_PARAMETER_ACCESS';
export const SET_NEW_PHONENO = 'SET_NEW_PHONENO';
export const SET_DEVICE_INFORMATION = 'SET_DEVICE_INFORMATION';
export const SET_IS_TNC_AGREEMENT = 'SET_IS_TNC_AGREEMENT';
export const SET_UPDATE_TNC_AGREEMENT = 'SET_UPDATE_TNC_AGREEMENT';
export const SET_DATA_RAYA_APPS = 'SET_DATA_RAYA_APPS';
export const SET_STATUS_AUTH_FLOW_RAYA = 'SET_STATUS_AUTH_FLOW_RAYA';
export const SET_CODE_AUTH_FLOW_RAYA = 'SET_CODE_AUTH_FLOW_RAYA';
export const SET_IS_RAYA_AGREEMENT = 'SET_IS_RAYA_AGREEMENT';
export const SET_ERROR_RAYA_AGREEMENT = 'SET_ERROR_RAYA_AGREEMENT';

//loanApply
export const SET_LOAN_PRODUCT_OPTIONS = 'SET_LOAN_PRODUCT_OPTIONS';
export const SET_LOAN_PURPOSE_OPTIONS = 'SET_LOAN_PURPOSE_OPTIONS';
export const SET_CUST_CATEGORY_OPTIONS = 'SET_CUST_CATEGORY_OPTIONS';
export const SET_BANK_OPTIONS = 'SET_BANK_OPTIONS';
export const SET_EDUCATION_OPTIONS = 'SET_EDUCATION_OPTIONS';
export const SET_FIELD_OF_WORK_OPTIONS = 'SET_FIELD_OF_WORK_OPTIONS';
export const SET_CREDIT_CARD_OPTIONS = 'SET_CREDIT_CARD_OPTIONS';
export const SET_PROVINCE_OPTIONS = 'SET_PROVINCE_OPTIONS';
export const SET_CITY_OPTIONS = 'SET_CITY_OPTIONS';
export const SET_SUB_DISTRICT_OPTIONS = 'SET_SUB_DISTRICT_OPTIONS';
export const SET_VILLAGE_OPTIONS = 'SET_VILLAGE_OPTIONS';
export const SET_JOB_TITLE_OPTIONS = 'SET_JOB_TITLE_OPTIONS';
export const SET_DAILY_NORMAL_TRANSACTIONS_OPTIONS =
  'SET_DAILY_NORMAL_TRANSACTIONS_OPTIONS';
export const SET_RESIDENCE_OPTIONS = 'SET_RESIDENCE_OPTIONS';
export const SET_EMPLOYEE_OPTIONS = 'SET_EMPLOYEE_OPTIONS';
export const SET_TYPE_OF_WORK_OPTIONS = 'SET_TYPE_OF_WORK_OPTIONS';
export const SET_FIELD_OF_BUSINESS_OPTIONS = 'SET_FIELD_OF_BUSINESS_OPTIONS';
export const SET_FIELD_OF_SUB_BUSINESS_OPTIONS =
  'SET_FIELD_OF_SUB_BUSINESS_OPTIONS';
export const SET_PURPOSE_OF_OPENING_ACCOUNT_OPTIONS =
  'SET_PURPOSE_OF_OPENING_ACCOUNT_OPTIONS';
export const SET_SOURCE_OF_INCOME_OPTIONS = 'SET_SOURCE_OF_INCOME_OPTIONS';
export const SET_COMPANY_OPTIONS = 'SET_COMPANY_OPTIONS';
export const SET_DEPENDENTS_OPTIONS = 'SET_DEPENDENTS_OPTIONS';
export const SET_MARRIAGE_OPTIONS = 'SET_MARRIAGE_OPTIONS';
export const SET_RELATIVES_OPTIONS = 'SET_RELATIVES_OPTIONS';
export const SET_SECOND_APPLY = 'SET_SECOND_APPLY';
export const SET_LOAN_SCHEME = 'SET_LOAN_SCHEME';
export const SET_LOAN_SCHEMES = 'SET_LOAN_SCHEMES';
export const SET_LOAN_FEE = 'SET_LOAN_FEE';
export const SET_NPWP = 'SET_NPWP';
export const SET_KTP_SUBMIT_FORM_RES = 'SET_KTP_SUBMIT_FORM_RES';
export const SET_AGREE_CHECKBOX_KTP = 'SET_AGREE_CHECKBOX_KTP';
export const SET_LOAN_AMOUNT = 'SET_LOAN_AMOUNT';
export const SET_TENURE = 'SET_TENURE';
export const SET_LOAN_PURPOSE = 'SET_LOAN_PURPOSE';
export const SET_PRODUCT_CONFIG = 'SET_PRODUCT_CONFIG';
export const SET_EKTP = 'SET_EKTP';
export const SET_EKTP_RAYA = 'SET_EKTP_RAYA';
export const SET_BANK_CODE = 'SET_BANK_CODE';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_CARD_NUMBER = 'SET_CARD_NUMBER';
export const SET_CARD_EXPIRED_DATE = 'SET_CARD_EXPIRED_DATE';
export const SET_CUSTOMER_NAME = 'SET_CUSTOMER_NAME';
export const SET_DISBURSE_LIFETIME = 'SET_DISBURSE_LIFETIME';
export const SET_SUBTRACTION_DISBURSE_LIFETIME =
  'SET_SUBTRACTION_DISBURSE_LIFETIME';
export const SET_ATM_INQUIRY_RESPONSE = 'SET_ATM_INQUIRY_RESPONSE';
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const SET_NEXT_DATE = 'SET_NEXT_DATE';
export const SET_APP_INQUIRY_RESPONSE = 'SET_APP_INQUIRY_RESPONSE';
export const SET_APPROVAL_DETAILS = 'SET_APPROVAL_DETAILS';
export const SET_INCOMPLETE_APP = 'SET_INCOMPLETE_APP';
export const RESUME_APPLICATION = 'RESUME_APPLICATION';
export const SET_VERIFY_PAYROLL = 'SET_VERIFY_PAYROLL';
export const SET_APPLICATION_DETAILS = 'SET_APPLICATION_DETAILS';
export const SET_DOCUMENT_DETAILS = 'SET_DOCUMENT_DETAILS';
export const SET_RESP_PAYROLL_DETAILS = 'SET_RES_PAYROLL_DETAILS';
export const SET_EKTP_IMG = 'SET_EKTP_IMG';
export const SET_PERSON_INFO = 'SET_PERSON_INFO';
export const SET_KTP_ADDRESS_DETAILS = 'SET_KTP_ADDRESS_DETAILS';
export const SET_OCCUPATION_INFO = 'SET_OCCUPATION_INFO';
export const SET_FAMILY_INFO = 'SET_FAMILY_INFO';
export const SET_CREDIT_SUCCESS = 'SET_CREDIT_SUCCESS';
export const SET_WHITELIST_ADDRESS = 'SET_WHITELIST_ADDRESS';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COMPANY_ADDRESS = 'SET_COMPANY_ADDRESS';
export const SET_DEHYDRATE_DATA = 'SET_DEHYDRATE_DATA';
export const SET_ALTERNATIVE_AMOUNT = 'SET_ALTERNATIVE_AMOUNT';
export const SET_ALTERNATIVE_TENURE = 'SET_ALTERNATIVE_TENURE';
export const SET_SUBCODE_LOANSCHEME = 'SET_SUBCODE_LOANSCHEME';
export const SET_WHITELIST_REFERENCE_V0 = 'SET_WHITELIST_REFERENCE_V0';
export const SET_RAYANET_INQUIRY = 'SET_RAYANET_INQUIRY';
export const SET_ACCOUNT_RAYA_INFORMATION = 'SET_ACCOUNT_RAYA_INFORMATION';
export const SET_ACCOUNT_INFORMATION_APP_INQ =
  'SET_ACCOUNT_INFORMATION_APP_INQ';
export const SET_NPWP_IMAGE = 'SET_NPWP_IMAGE';
export const SET_ACCOUNT_RAYA_SAVING_EXIST = 'SET_ACCOUNT_RAYA_SAVING_EXIST';
export const SET_FLAGBOX = 'SET_FLAGBOX';
export const SET_BLOCK_SCREEN = 'SET_BLOCK_SCREEN';
export const SET_BOTTOM_SHEET = 'SET_BOTTOM_SHEET';
export const SET_IS_MAINTENANCE = 'SET_IS_MAINTENANCE';
export const SET_IS_EXIST_WHITELIST = 'SET_IS_EXIST_WHITELIST';
export const SET_IS_COMPANY_UPDATED = 'SET_IS_COMPANY_UPDATED';
export const SET_KASBON_INQUIRY = 'SET_KASBON_INQUIRY';
export const SET_IS_SAME_CONTACT_OTHER = 'SET_IS_SAME_CONTACT_OTHER';
export const SET_WHITELIST_INQUIRY = 'SET_WHITELIST_INQUIRY';
export const SET_COMPANY_AGENCY_OPTIONS = 'SET_COMPANY_AGENCY_OPTIONS';

//disburse
export const SET_TIMER = 'SET_TIMER';
export const START_TIMER = 'START_TIMER';
export const DEC_MINUTES = 'DEC_MINUTES';
export const DEC_SECONDS = 'DEC_SECONDS';
export const STOP_TIMER = 'STOP_TIMER';

export const CREDIT_SCORE_RESPONSE = 'CREDIT_SCORE_RESPONSE';
export const SET_CS_RESULT = 'SET_CS_RESULT';
export const SET_OFFERING_1 = 'SET_OFFERING_1';
export const SET_OFFERING_2 = 'SET_OFFERING_2';
export const SET_SELECTED_OFFERING = 'SET_SELECTED_OFFERING';
export const SET_LOAN_AMOUNT_SELECETED = 'SET_LOAN_AMOUNT_SELECETED';
export const TAKE_SELFIE_PRIVY = 'TAKE_SELFIE_PRIVY';
export const SET_PRIVY_TNC = 'SET_PRIVY_TNC';
export const SET_PRIVY_REG_DATA = 'SET_PRIVY_REG_DATA';
export const SET_ESIGN_REG_DATA = 'SET_ESIGN_REG_DATA';
export const SET_EKYC_VENDOR = 'SET_EKYC_VENDOR';
export const SET_VALIDATE_PHOTO_SIGNATURE = 'SET_VALIDATE_PHOTO_SIGNATURE';
export const SET_ESIGN_STATUS = 'SET_ESIGN_STATUS';
export const SET_PINANG_DOC_1 = 'SET_PINANG_DOC_1';
export const SET_PINANG_DOC_2 = 'SET_PINANG_DOC_2';
export const SET_PINANG_DOC_3 = 'SET_PINANG_DOC_3';
export const SET_PINANG_DOC_4 = 'SET_PINANG_DOC_4';
export const SET_DOC_RIPLAY = 'SET_DOC_RIPLAY';
export const SET_IS_CUSTOMER_CONSENT = 'SET_IS_CUSTOMER_CONSENT';
export const SET_PRIVY_TOKEN = 'SET_PRIVY_TOKEN';
export const TAKE_SELFIE_FINISH = 'TAKE_SELFIE_FINISH';
export const SET_PRIVY_VALID_RES = 'SET_PRIVY_VALID_RES';
export const SET_CREATE_LOAN_RES = 'SET_CREATE_LOAN_RES';
export const SET_REGISTRATION_STATUS = 'SET_REGISTRATION_STATUS';
export const SET_REGISTRATION_STATUS_INCOMING =
  'SET_REGISTRATION_STATUS_INCOMING';
export const SET_RAYA_ACCOUNT_NUMBER = 'SET_RAYA_ACCOUNT_NUMBER';
export const SET_BRI_ACCOUNT_NUMBER = 'SET_BRI_ACCOUNT_NUMBER';
export const SET_RAYA_ACCOUNT_NAME = 'SET_RAYA_ACCOUNT_NAME';
export const SET_RAYA_CIF = 'SET_RAYA_CIF';
export const SET_URL_RAYA = 'SET_URL_RAYA';
export const COUNT_TAKE_PICTURE = 'COUNT_TAKE_PICTURE';
export const RESET_TAKE_PICTURE = 'RESET_TAKE_PICTURE';
export const SET_APPLICATION_DOC = 'SET_APPLICATION_DOC';
export const SET_PASSIVE_LIVENESS_IMAGE = 'SET_PASSIVE_LIVENESS_IMAGE';
export const SET_PASSIVE_LIVENESS_IMAGE_VERIF =
  'SET_PASSIVE_LIVENESS_IMAGE_VERIF';
export const SET_EKYC_KEY = 'SET_EKYC_KEY';
export const SET_EKYC_STATUS = 'SET_EKYC_STATUS';
export const SET_ACCT_CREATED_FROM_SAVING = 'SET_ACCT_CREATED_FROM_SAVING';
export const SET_RESP_KTP_SUBMIT = 'SET_RESP_KTP_SUBMIT';
export const SET_RESPONSE_CODE_REJECT = 'SET_RESPONSE_CODE_REJECT';

//linkaja
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_VERIFY_SESSION_RES = 'SET_VERIFY_SESSION_RES';
export const SET_CASH_INQUIRY_RES = 'SET_CASH_INQUIRY_RES';
export const SET_DIRECT_DEBIT_RES = 'SET_DIRECT_DEBIT_RES';

//history
export const SET_HISTORY_PAYMENTS = 'SET_HISTORY_PAYMENTS';
export const SET_HISTORY_LOANS = 'SET_HISTORY_LOANS';

//Element
export const TAKE_SELFIE_ELEMENT = 'TAKE_SELFIE_ELEMENT';
export const TIMEOUT_SELFIE = 'TIMEOUT_SELFIE';

// Forgot Pin
export const SET_FORGOT_PIN_NEW_PIN_ALREADY_USED =
  'SET_FORGOT_PIN_NEW_PIN_ALREADY_USED';
export const SET_RETYPE_SIGN_ON_PIN = 'SET_RETYPE_SIGN_ON_PIN';
export const SET_TYPE_SIGN_ON_PIN = 'SET_TYPE_SIGN_ON_PIN';

//notification
export const FETCH_NOTIFICATION_USER = 'FETCH_NOTIFICATION_USER';

//help FAQ
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';

//Payment
export const FECT_MESSAGE_PAYMENT = 'FECT_MESSAGE_PAYMENT';
export const PAYMENT_INQUIRY = 'PAYMENT_INQUIRY';
export const PAYMENT_TRANSACTION = 'PAYMENT_TRANSACTION';
export const STATUS_PAYMENT = 'STATUS_PAYMENT';
export const LAST_PAYMENT = 'LAST_PAYMENT';

//Profile
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

//LogOut
export const RESET_LOGOUT = 'RESET_LOGOUT';

export const SET_ATTEMPT_SUBMISSION = 'SET_ATTEMPT_SUBMISSION';
export const SHOULD_MANUAL_CHECK = 'SHOULD_MANUAL_CHECK';
export const SET_STAY_PERIOD = 'SET_STAY_PERIOD';
