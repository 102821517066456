import React from 'react';

function BannerImage({ srcImage, onBannerClick }) {
  return (
    <div onClick={onBannerClick}>
      <span />
      <img src={srcImage} style={{ borderRadius: '8px' }} width="100%" />
    </div>
  );
}

export default BannerImage;
