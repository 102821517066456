import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';

const WithTransition = props => {
  const [trans, setTrans] = React.useState(false);

  React.useEffect(() => {
    const interval = setTimeout(() => {
      setTrans(true);
    }, 300);
    return () => clearTimeout(interval);
  }, []);

  let transition = null;

  switch (props.transitionStyle) {
    case 'collapse':
      transition = (
        <Collapse in={props.in ? props.in : trans}>
          <div className={props.className}>{props.children}</div>
        </Collapse>
      );
      break;
    case 'grow':
      transition = (
        <Grow in={props.in ? props.in : trans}>
          <div className={props.className}>{props.children}</div>
        </Grow>
      );
      break;
    case 'slide':
      transition = (
        <Slide in={props.in ? props.in : trans}>
          <div className={props.className}>{props.children}</div>
        </Slide>
      );
      break;
    case 'zoom':
      transition = (
        <Zoom in={props.in ? props.in : trans}>
          <div className={props.className}>{props.children}</div>
        </Zoom>
      );
      break;
    default:
      transition = (
        <Fade in={props.in ? props.in : trans}>
          <div className={props.className}>{props.children}</div>
        </Fade>
      );
      break;
  }

  return <>{transition}</>;
};

export default WithTransition;
