import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
const CustomDrawer = props => {
  const { children, drawer, setDrawer, isNotClose, closeHandler } = props;

  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        style: {
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
          padding: '16px',
          maxWidth: '500px',
          maxHeight: '100%',
          margin: '0 auto'
        }
      }}
      anchor="bottom"
      onClose={() => setDrawer(prev => !prev)}
      // onOpen={() => setDrawer(drawer)}
      open={drawer}
    >
      <span
        style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}
      >
        <img alt="line" src="/images/assets/line.svg" />
      </span>
      {!isNotClose && (
        <span
          onClick={closeHandler}
          style={{ position: 'absolute', right: '8px', top: '8px' }}
        >
          <img alt="close" src="/images/assets/close.svg" />
        </span>
      )}
      <div
        style={{
          alignItems: 'center',
          marginTop: '1rem'
        }}
      >
        {children}
      </div>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  children: PropTypes.node,
  closeHandler: PropTypes.func,
  drawer: PropTypes.bool,
  isNotClose: PropTypes.bool,
  setDrawer: PropTypes.func
};

export default CustomDrawer;
