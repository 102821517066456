import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';

// Material components
import Paper from '@material-ui/core/Paper';

// Component styles
const styles = theme => {
  return {
    root: {
      borderRadius: '8px',
      boxShadow: `0px 0px 1px ${theme.palette.surfaceBoxShadow}, 0px 2px 4px ${theme.palette.surfaceBoxShadow2}`
    }
  };
};

const SurfacePaper = props => {
  const { classes, className, children, ...rest } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true
    },
    className
  );

  return (
    <Paper {...rest} className={rootClassName}>
      {children}
    </Paper>
  );
};

SurfacePaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

SurfacePaper.defaultProps = {
  elevation: 0
};

export default withStyles(styles)(SurfacePaper);
