import { orange, textSecondary } from 'common/colors';

export default {
  root: {
    height: '50px',
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '14px',
    '@media (min-width: 960px)': {
      minWidth: '100px'
    },
    '&$selected': {
      fontWeight: 500,
      color: orange
    }
  },
  // label: {},
  // labelContainer: {},
  textColorPrimary: {
    color: textSecondary
  }
};
