import { gray, green, textPrimary, textSecondary } from 'common/colors';

export default {
  h1: {
    color: textPrimary,
    fontWeight: '500',
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
    fontFamily: 'Muli'
  },
  h2: {
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '40px',
    fontFamily: 'Lato'
  },
  h3: {
    color: green,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px'
  },
  h4: {
    color: textPrimary,
    fontWeight: '700',
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
    fontFamily: 'Lato'
  },
  h5: {
    color: textPrimary,
    fontWeight: '700',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '16px',
    fontFamily: 'Lato'
  },
  h6: {
    color: textSecondary,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Lato'
  },
  subtitle1: {
    color: textPrimary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px'
  },
  subtitle2: {
    color: textPrimary,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '21px'
  },
  body1: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '21px',
    fontFamily: 'Lato'
  },
  body2: {
    color: gray,
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px'
  },
  button: {
    color: textPrimary,
    fontSize: '14px'
  },
  caption: {
    color: textSecondary,
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: '400',
    fontFamily: 'Lato'
  },
  caption2: {
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontFamily: 'Lato'
  },
  colorPrimary: {
    color: textPrimary
  },
  labelChip: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '16px',
    textAlign: 'center'
  }
};
