import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  contentTextOne: {
    fontSize: '16px',
    fontWeight: '700'
  },
  contentTextTwo: {
    lineHeight: '20px',
    color: theme.palette.text.secondary
  }
}));
