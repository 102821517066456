import * as actionTypes from './actionTypes';
import axiosGolang from 'lib/axios/axios-golang';
import { setError } from './auth';
import { USERS, NOTIFICATION } from '../../common/config';
import { getChannelContextCode } from '../../helpers/Utility';

export const setNotification = data => {
  return {
    type: actionTypes.FETCH_NOTIFICATION_USER,
    message: data
  };
};

export const fetchingNotification = (phoneNo, token) => {
  return dispatch => {
    const header = {
      headers: {
        Authorization: token
      }
    };
    const url = USERS + NOTIFICATION;

    axiosGolang
      .get(url, header)
      .then(res => {
        // When the Notification is Empty on User
        if (res.data.code === 8504) {
          dispatch(setNotification([]));
          return;
        }

        res.data.list && dispatch(setNotification(res.data.list));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '8504') {
          dispatch(setNotification([]));
          return;
        }

        dispatch(setError('system'));
      })
  };
};

export const setNotificationStatus = param => {
  return dispatch => {
    const url = USERS + NOTIFICATION + '/' + param.notifId;
    const header = {
      headers: {
        Authorization: param.token
      }
    };

    axiosGolang
      .put(url, {}, header)
      .then(res => {
        return res;
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};
