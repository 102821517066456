import { divider, textPrimary } from 'common/colors';

export default {
  root: {
    borderBottom: `1px solid ${divider}`
  },
  head: {
    fontSize: '14px',
    color: textPrimary
  },
  sizeSmall: {
    padding: '8px'
  }
};
