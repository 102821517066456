import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  subHeading: {
    fontWeight: '700',
    fontSize: '18px'
  },
  subHeading2: {
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    width: '100%'
  }
}));
