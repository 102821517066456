/**
 * Extract PDF Blob Data to be Downloaded via BLOB URL
 * @param {Blob Data} data
 * @param {object} disposition
 * @returns {HTMLAnchorElement}
 */
export const downloadPDF = (data, disposition) => {
  // Set filename for document pdf name
  let filename;
  if (disposition && disposition.indexOf('attachment') !== -1) {
    let filenameRegex = /fileName[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const blobData = [data];

  // insert filename to type of blob (doing manipulation to carry the name on blob URL)
  const blob = new Blob(blobData, { type: `application/${filename}` });

  // Building Blob object from Blob Data
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);

  // Creating Anchor Element for auto download
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};
