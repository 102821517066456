import React from 'react';
import { Card, CardHeader } from '@material-ui/core';

const CardLiveChat = ({
  actionCard,
  avatarCard,
  subheaderCard,
  titleCard,
  classCard,
  classHeaderCard
}) => {
  return (
    <>
      <Card className={classCard}>
        <CardHeader
          action={actionCard}
          avatar={avatarCard}
          className={classHeaderCard}
          subheader={subheaderCard}
          title={titleCard}
        />
      </Card>
    </>
  );
};

export default CardLiveChat;
