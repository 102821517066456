import { background, neutralNr20, neutralNr30 } from 'common/colors';

export default {
  root: {
    // height: '56px',
    '&$selected': {
      backgroundColor: background
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: background
      }
    },
    '&:nth-of-type(odd)': {
      backgroundColor: neutralNr30
    },
    '&:nth-of-type(even)': {
      backgroundColor: neutralNr20
    }
  }
};
