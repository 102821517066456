import { getPartnerId } from 'helpers/getPartnerId';
import { PARTNER_ID_BPJS, PARTNER_ID_BPJS_KASBON } from 'common/config';
import {
  primaryBpjs10,
  primaryBpjs5,
  primaryBpjs50,
  primaryPr10,
  primaryPr50,
  secondarySc10,
  secondarySc50
} from 'common/colors';

export const primaryColorByPartner =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs50
    : primaryPr50;
export const colorByPartner =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs50
    : secondarySc50;
export const disableColorByPartner =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs5
    : secondarySc10;
export const bgColorByPartnerId =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs5
    : secondarySc10;
export const textColorBottomNavBarbyPartner =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs50
    : primaryPr50;
export const lightColorByPartner =
  getPartnerId === PARTNER_ID_BPJS || getPartnerId === PARTNER_ID_BPJS_KASBON
    ? primaryBpjs10
    : primaryPr10;
