export default () => ({
  bannerNotif: {
    padding: '12px'
  },
  bannerNotifIcon: {
    marginRight: '5px'
  },
  captionBold: {
    fontFamily: 'Lato',
    fontWeight: 700
  }
});
