export default theme => ({
  root: {
    alignContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
    display: 'flex',
    padding: '0px 10px',
    width: 'inherit',
    justifyContent: 'center'
  },
  isInline: {
    display: 'inline-block'
  },
  isFit: {
    width: 'max-content'
  },
  isSuccess: {
    backgroundColor: '#38BAA7'
  },
  isDanger: {
    backgroundColor: theme.palette.common.stateErrorRaya
  },
  isWarning: {
    backgroundColor: theme.palette.common.orange
  },
  isPending: {
    backgroundColor: 'rgba(245, 138, 35, 0.1)',
    padding: theme.spacing(0.5)
  },
  isNotice: {
    background: 'rgba(76, 187, 108, 0.1)',
    padding: theme.spacing(0.5)
  },
  isPrimary: {
    background: theme.palette.common.primaryPr40,
    padding: '2px 32px'
  },
  isSecondary: {
    padding: theme.spacing(1),
    background: 'rgba(242, 101, 124, 0.1);'
  },
  text: {
    fontWeight: 'Normal',
    color: theme.palette.common.white
  },
  infoText: {
    color: theme.palette.common.orange
  },
  noticeText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px'
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px'
  },
  secondaryText: {
    color: theme.palette.common.stateErrorRaya,
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px'
  },
  pendingText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#F58A23',
    padding: '2px'
  }
});
