import React from 'react';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';

import { Typography } from '@material-ui/core';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Component styles
const styles = () => {
  return {
    root: {
      textAlign: 'left',
      marginTop: '16px',
      marginLeft: '16px'
    },
    topRight: {
      textAlign: 'right',
      marginTop: '16px',
      marginRight: '16px'
    },
    bottomLeft: {
      textAlign: 'left',
      marginTop: '8px',
      marginLeft: '16px',
      marginBottom: '16px'
    },
    bottomRight: {
      textAlign: 'right',
      marginTop: '8px',
      marginRight: '16px',
      marginBottom: '16px'
    },
    smallMid: {
      fontSize: '12px',
      lineHeight: '16px',
      marginTop: '0px',
      marginLeft: '0px',
      textAlign: 'center'
    }
  };
};

const SurfaceGridInfo = props => {
  const {
    classes,
    children,
    className,
    topRight,
    bottomLeft,
    bottomRight,
    smallMid,
    ...rest
  } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.topRight]: topRight,
      [classes.bottomLeft]: bottomLeft,
      [classes.bottomRight]: bottomRight,
      [classes.smallMid]: smallMid
    },
    className
  );

  return (
    <Typography className={rootClassName} {...rest}>
      {children}
    </Typography>
  );
};

SurfaceGridInfo.propTypes = {
  bottomLeft: PropTypes.bool,
  bottomRight: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  smallMid: PropTypes.bool,
  topRight: PropTypes.bool
};

SurfaceGridInfo.defaultProps = {
  topRight: false,
  bottomLeft: false,
  bottomRight: false,
  smallMid: false
};

export default withStyles(styles)(SurfaceGridInfo);
