import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { white } from 'common/colors';
import styles from './styles';

const ContentDocument = props => {
  const { classes, content, setError, docType = '' } = props;

  const isDTRP = type => {
    if (type === 'DTRP') return true;
    else return false;
  };

  const getTextTable = item => {
    if (isDTRP(docType)) {
      return (
        <>
          {item.text && (
            <p
              className={classes.titleTable}
              style={{
                marginLeft: item.indentLevel + 'vh'
              }}
            >
              {item.srlNumber !== 'NULL' && <b>{item.srlNumber}. </b>}
              {item.text}
            </p>
          )}
          <TableContainer
            className={
              item.table.style === 'outline'
                ? classes.tableOutlined
                : classes.tableContainer
            }
          >
            <Table aria-label="customized table">
              <TableBody>
                {React.Children.toArray(
                  item.table.rows.map((row, indexRow) => {
                    return (
                      <>
                        <TableRow
                          key={indexRow}
                          style={{
                            backgroundColor: row.color ? row.color : white
                          }}
                        >
                          <TableCell
                            align="left"
                            className={
                              item.table.style === 'outline'
                                ? classes.tableCellOutlined
                                : classes.tableCell
                            }
                          >
                            <Typography
                              style={{
                                color: row.colorText ? row.colorText : ''
                              }}
                              variant="h6"
                            >
                              {row.label}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={
                              item.table.style === 'outline'
                                ? classes.tableCellOutlined
                                : classes.tableCell
                            }
                          >
                            <Typography
                              style={{
                                color: row.colorText ? row.colorText : ''
                              }}
                              variant="body1"
                            >
                              {row.value}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    } else {
      return (
        <div
          className={isDTRP(docType) ? classes.bodyRiplay : classes.body}
          style={{ overflow: 'auto' }}
        >
          {item.table.rows.map(row => (
            <p key={row.label}>
              {row.label} : {row.value}
            </p>
          ))}
        </div>
      );
    }
  };

  const getContents = () => {
    if (content && content?.find(item => item.displayOrder)) {
      return Object.keys(content).map(index => (
        <Grid container direction="row" key={index}>
          <Grid
            className={
              isDTRP(docType) ? classes.subTitleRiplay : classes.subTitle
            }
            item
          >
            {content[index].textName}
          </Grid>
          <Grid
            className={isDTRP(docType) ? classes.bodyRiplay : classes.body}
            item
          >
            {content[index].textDescription}
          </Grid>
        </Grid>
      ));
    } else if (content && content?.find(item => item.textName)) {
      return (
        <>
          {content.map(item => (
            <div id="doc" key={item.textName}>
              {item.textType === 'HEADER' && (
                <p
                  className={
                    isDTRP(docType) ? classes.subTitleRiplay : classes.subTitle
                  }
                  style={{ marginLeft: item.indentLevel + 'vh' }}
                >
                  {item.srlNumber !== 'NULL' && <b>{item.srlNumber}. </b>}
                  {item.text}
                </p>
              )}
              {item.textType === 'TEXT' && (
                <p
                  className={
                    isDTRP(docType) ? classes.bodyRiplay : classes.body
                  }
                  style={{ marginLeft: item.indentLevel + 'vh' }}
                >
                  {item.srlNumber !== 'NULL' && <b>{item.srlNumber}. </b>}
                  {ReactHtmlParser(item.text)}
                </p>
              )}
              {item.textType === 'TABLE' && getTextTable(item)}

              {item.textType === 'LIST' && (
                <>
                  {item.text && (
                    <p
                      className={
                        isDTRP(docType)
                          ? classes.subTitleRiplay
                          : classes.subTitle
                      }
                      style={{
                        marginLeft: item.indentLevel + 'vh',
                        marginTop: '16px'
                      }}
                    >
                      {item.srlNumber !== 'NULL' && <b>{item.srlNumber}. </b>}
                      {item.text}
                    </p>
                  )}

                  <List>
                    {item.list.contents.map((temp, index) => (
                      <ListItem
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          padding: '0px'
                        }}
                      >
                        <ListItemIcon
                          style={{
                            paddingTop: '4px',
                            minWidth: 'auto',
                            marginRight: '8px'
                          }}
                        >
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: item.list.color, width: '8px' }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={temp.text} />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </div>
          ))}
        </>
      );
    } else {
      setError('system');
    }
  };

  return <>{getContents()}</>;
};
export default withStyles(styles)(ContentDocument);
