export default {
  colorSecondary: {
    '&$checked': {
      // "&:checked": {
      color: '#F58A23'
      // }
    }
    // backgroundColor: greyishContainer
  }
  //   checked: {
  //     // '&:checked': {
  //     //   color: 'red'
  //     // },
  //     // '&:before': {
  //     //   backgroundColor: greyishContainer
  //     // },
  //     // '&:after': {
  //     //   backgroundColor: greyishContainer
  //     // }
  //   },
};
