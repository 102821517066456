export default () => ({
  titleText: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#444444',
    marginBottom: '16px'
  },
  subTitle: {
    marginBottom: '8px'
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    borderRadius: 10
  },
  rootGrid: {
    padding: 20,
    alignItems: 'center'
  },
  logo: {
    margin: 'auto'
  },
  descText: {
    lineHeight: '20px',
    marginTop: 2
  },
  dotText: {
    // marginLeft: "16px",
  },
  drawerContainer: {
    padding: '30px'
  }
});
