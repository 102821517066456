import React from 'react';
import { useLocation } from 'react-router-dom';

import analytics from 'helpers/analytics';

const useGoogleAnalytics = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (!window.GA_INITIALIZED) {
      analytics.init({
        debug: false
      });
      window.GA_INITIALIZED = true;
    }
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageView(currentPath);
  }, [location]);
};

export default useGoogleAnalytics;
