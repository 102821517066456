export default {
  iconContainer: {
    zIndex: 1
  },
  label: {
    '&.MuiStepLabel-alternativeLabel': {
      marginTop: '8px'
    }
  }
};
