/* eslint-disable react/no-multi-comp */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Button,
  Slide
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import classesCss from './style.module.css';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      {props.children ? (
        <Typography variant="h4">{children}</Typography>
      ) : (
        <div style={{ height: '2vh' }} />
      )}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomizedDialogs(props) {
  let content = '';

  switch (props.variant) {
    case 'confirm-dialog':
      content = (
        <div className={classesCss.ConfirmDialog}>
          {props.logo && (
            <img
              alt=""
              className={classesCss.TopImg}
              src="images/assets/standard.svg"
            />
          )}
          <div className={classesCss.Img}>{props.img}</div>
          <Typography
            className={
              props.type === 'disburse-success'
                ? classesCss.ConfirmCaptionDisburseSuccess
                : classesCss.ConfirmCaption
            }
            color="textPrimary"
            variant="h3"
          >
            {props.caption}
          </Typography>
          {props.confirmed && (
            <Button
              className={classesCss.ConfirmButton}
              color="primary"
              onClick={props.confirmed}
              variant="contained"
            >
              Lanjutkan
            </Button>
          )}
        </div>
      );
      break;
    default:
      content = <div className={classesCss.Content}>{props.children}</div>;
      break;
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-labelledby="customized-dialog-title"
      className={props.className}
      fullScreen={props.fullScreen ? true : false}
      onClose={props.closed}
      open={props.show}
      style={{ maxWidth: '500px', margin: '0 auto' }}
      transitionDuration={340}
    >
      <DialogTitle
        className={props.classNameTitle}
        id="customized-dialog-title"
        onClose={props.closed}
      >
        {props.title}
      </DialogTitle>
      <DialogContent
        className={props.classNameContent}
        dividers={props.dividers}
        onScroll={props.onScroll}
        ref={props.reference}
      >
        {content}
      </DialogContent>
      {props.isAction && (
        <DialogActions>
          <Button color="primary" onClick={props.closed} style={{ width: 0 }}>
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
