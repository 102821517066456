import axios from 'axios';
import { OAUTH_BASE_URL_GOLANG } from 'common/config';

const instance = axios.create({
  baseURL: OAUTH_BASE_URL_GOLANG,
  timeout: 60000
  // withCredentials: false,
  // headers: {
  // 'Access-Control-Allow-Origin': '*',
  // }
});

export default instance;
