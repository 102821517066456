export default theme => ({
  divHeader: {
    margin: '0 0 20px 0',
    width: '100%'
  },
  accountNoText: {
    fontWeight: 700
  },
  fontDisb: {
    fontWeight: 400
  },
  icon: {
    margin: 'auto'
  },
  portletStyle: {
    backgroundColor: theme.palette.common.neutralNr20,
    padding: '8px 16px',
    margin: '16px 0px'
  },
  portletDivider: {
    width: '220px',
    margin: '8px 0'
  },
  padDiv: {
    padding: '16px 0 0 0'
  },
  roundNumber: {
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    width: '24px',
    height: '24px'
  },
  numberStyle: {
    position: 'inherit',
    color: 'white',
    top: '2px'
  },
  dividerVertical: {
    maxHeight: '160px',
    minHeight: '150px',
    marginLeft: '15px',
    border: `1px dashed ${theme.palette.primary.main}`
  },
  snackbarStyle: {
    backgroundColor: `${theme.palette.common.stateSuccessRaya}`,
    borderRadius: '8px',
    marginBottom: theme.spacing(8)
  },
  snackbarStyleMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  snackText: {
    color: theme.palette.common.white
  }
});
