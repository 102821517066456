import React from 'react';

import { Card, CardActions, CardHeader, Button } from '@material-ui/core';

const CardInfo = ({
  subheader,
  title,
  buttonLabel,
  styleClass,
  styleCardActions,
  styleLabelButton,
  image,
  onClick
}) => {
  return (
    <>
      <Card className={styleClass}>
        <CardHeader avatar={image} subheader={subheader} title={title} />
        <CardActions className={styleCardActions}>
          <Button
            className={styleLabelButton}
            color="secondary"
            // onClick={() => onClick()}
            onClick={onClick}
            variant="text"
          >
            {buttonLabel}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CardInfo;
