import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import classes from './style.module.css';
import { getPartnerId } from 'helpers/getPartnerId';
import { getIconBackSpaceforPin } from 'helpers/getAssetsByPartner';
import { white } from 'common/colors';

const FIRST_ROW = ['1', '2', '3'];
const SECOND_ROW = ['4', '5', '6'];
const THIRD_ROW = ['7', '8', '9'];

const RoundButton = withStyles(theme => ({
  root: {
    backgroundColor: '',
    borderRadius: '50%',
    color: white,
    padding: '0.55em 0.8em',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.75rem',
    lineHeight: '32px',
    minWidth: '0px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '@media (max-height: 500px)': {
    root: {
      fontSize: '1.4rem',
      padding: '0.4em 0.75em'
    }
  }
}))(Button);

const NumPad = props => {
  const firstRow = FIRST_ROW.map(key => {
    return (
      <Grid item key={key}>
        <RoundButton
          // disabled={props.disabled}
          onClick={() => props.add(key)}
        >
          {key}
        </RoundButton>
      </Grid>
    );
  });

  const secondRow = SECOND_ROW.map(key => {
    return (
      <Grid item key={key}>
        <RoundButton
          // disabled={props.disabled}
          onClick={() => props.add(key)}
        >
          {key}
        </RoundButton>
      </Grid>
    );
  });

  const thirdRow = THIRD_ROW.map(key => {
    return (
      <Grid item key={key}>
        <RoundButton
          // disabled={props.disabled}
          onClick={() => props.add(key)}
        >
          {key}
        </RoundButton>
      </Grid>
    );
  });

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        item
        justify="space-evenly"
        style={{ marginBottom: '12px' }}
      >
        {firstRow}
      </Grid>
      <Grid
        container
        direction="row"
        item
        justify="space-evenly"
        style={{ marginBottom: '12px' }}
      >
        {secondRow}
      </Grid>
      <Grid
        container
        direction="row"
        item
        justify="space-evenly"
        style={{ marginBottom: '12px' }}
      >
        {thirdRow}
      </Grid>
      <Grid container direction="row" item justify="space-evenly">
        <Grid item>
          <Button className={classes.KeyPad}> </Button>
        </Grid>
        <Grid item>
          <RoundButton
            // disabled={props.disabled}
            onClick={() => props.add('0')}
          >
            0
          </RoundButton>
        </Grid>
        <Grid item>
          <Button
            className={classes.KeyPad}
            // disabled={props.disabled}
          >
            <img
              alt="esc"
              onClick={props.del}
              src={getIconBackSpaceforPin(getPartnerId)}
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NumPad;
