import React from 'react';
import PropTypes from 'prop-types';

import Paper from '../Paper';
import DividerCard from 'components/DividerCard';
import StatusBox from '../StatusBox';

import {
  Grid,
  withStyles,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

import { dueDateFormat, creditCardFormating } from 'helpers/Utility';

import styles from './styles';

const InvoicePanel = ({
  classes,
  loanNumber,
  title,
  cicilanPerbulan,
  pendingInstallment,
  totalAmount,
  confirmHandler,
  isDetail,
  agree,
  dueDate,
  sactionAmount,
  rate,
  isLate,
  pinalty,
  lateFee,
  overDueDays,
  bankCode,
  payrollAccount
}) => {
  const paymentLimitBody = propsIsDetail => {
    if (propsIsDetail) {
      return (
        <Typography color="textPrimary" variant="body1">
          Batas Pelunasan
        </Typography>
      );
    } else {
      return (
        <Typography color="textPrimary" variant="body1">
          Pokok Pinjaman
        </Typography>
      );
    }
  };

  const paymentLimitSubtitle = propsIsDetail => {
    if (propsIsDetail) {
      return (
        <Typography color="textPrimary" variant="subtitle2">
          {dueDateFormat(dueDate)}
        </Typography>
      );
    } else {
      return (
        <Typography color="textPrimary" variant="subtitle2">
          Rp {sactionAmount}
        </Typography>
      );
    }
  };

  const informationBox = (propsIsDetail, propsIsLate) => {
    if (propsIsDetail) {
      if (propsIsLate) {
        return (
          <StatusBox center warning>
            Pastikan saldo rekening anda mencukupi
          </StatusBox>
        );
      } else {
        return (
          <Typography color="textPrimary" variant="body2">
            Pastikan saldo kamu mencukupi untuk pembayaran cicilan secara
            autodebet (pembayaran otomatis).
          </Typography>
        );
      }
    } else {
      return (
        <FormControlLabel
          control={
            <Checkbox checked={agree} style={{ transform: 'scale(1.5)' }} />
          }
          label={
            <Typography
              color="textPrimary"
              style={{ marginRight: '16px' }}
              variant="body2"
            >
              Saya setuju bayar lunas sekaligus
            </Typography>
          }
          labelPlacement="end"
          onClick={confirmHandler}
        />
      );
    }
  };

  const getBankName = () => {
    if (bankCode === '002') return 'BRI';
    else return 'Raya';
  };

  return (
    <Paper
      className={isLate ? classes.paperRootLate : classes.paperRoot}
      elevation={0}
      outlined
    >
      <Grid alignContent="stretch" container direction="column" spacing={2}>
        <Grid item>
          <StatusBox notice>Pinjaman Flexi #{loanNumber}</StatusBox>
        </Grid>
        <Grid item>
          <Typography className={classes.titleText} variant="h5">
            {title}
          </Typography>
        </Grid>
        <DividerCard kind ="solid"/>
        { isLate ? (
          <>
            <Grid item>
              <Grid
                alignContent="center"
                container
                direction="column"
                spacing={2}
              >
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="body1">
                      Lama Tunggakan
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      {overDueDays} Hari
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="body1">
                      Pokok
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      Rp{sactionAmount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      Bunga
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      {rate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      Denda
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      {lateFee}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.amountText}>
                      Rp{totalAmount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DividerCard kind="solid" />
            <Grid item>
              <StatusBox center secondary>
                Pastikan saldo rekening anda mencukupi untuk melunasi tunggakan
              </StatusBox>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Grid
                alignContent="center"
                container
                direction="column"
                spacing={2}
              >
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="body1">
                      Rekening {getBankName()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      {creditCardFormating(payrollAccount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="body1">
                      Metode Pembayaran
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary" variant="subtitle2">
                      Autodebet
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                  style={{ marginBottom: '16px' }}
                >
                  <Grid item>{paymentLimitBody(isDetail)}</Grid>
                  <Grid item>{paymentLimitSubtitle(isDetail)}</Grid>
                </Grid>
                <DividerCard kind ="solid" />
                {isDetail && (
                  <>
                    <Grid
                      alignContent="center"
                      container
                      direction="row"
                      item
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography color="textPrimary" variant="body1">
                          Pokok
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="subtitle2">
                          {sactionAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      alignContent="center"
                      container
                      direction="row"
                      item
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography color="textPrimary" variant="body1">
                          Bunga 1.5%
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="subtitle2">
                          {rate}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!isDetail && (
                  <Grid
                    alignContent="center"
                    container
                    direction="row"
                    item
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography color="textPrimary" variant="body1">
                        Sisa Cicilan
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="textPrimary" variant="subtitle2">
                        {pendingInstallment}x Rp {cicilanPerbulan}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {isLate && (
                  <>
                    <Grid
                      alignContent="center"
                      container
                      direction="row"
                      item
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography color="textPrimary" variant="body1">
                          Denda 0.5%/Hari
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textPrimary" variant="subtitle2">
                          {pinalty}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid
                  alignContent="center"
                  container
                  direction="row"
                  item
                  justify="space-between"
                  style={{ marginBottom: '16px' }}
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="body1">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.amountText}>
                      Rp{totalAmount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DividerCard kind="solid" />
            <Grid item>{informationBox(isDetail, isLate)}</Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

InvoicePanel.propTypes = {
  agree: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  confirmHandler: PropTypes.func,
  dueDate: PropTypes.string,
  feeAmount: PropTypes.string,
  fromInstallement: PropTypes.string,
  isDetail: PropTypes.bool,
  isLate: PropTypes.bool,
  lateFee: PropTypes.string,
  limitDate: PropTypes.string,
  loanNumber: PropTypes.string,
  overDueDays: PropTypes.string,
  pinalty: PropTypes.string,
  rate: PropTypes.string,
  sactionAmount: PropTypes.string,
  tglPutusan: PropTypes.string,
  title: PropTypes.string,
  totalAmount: PropTypes.string,
  untillInstallement: PropTypes.string
};

export default withStyles(styles)(InvoicePanel);
