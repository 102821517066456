import React from 'react';
import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    borderRadius: '8px',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    '&:last-child': {
      borderRadius: '8px'
    },
    '&:first-child': {
      borderRadius: '8px'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}))(MuiAccordionDetails);

const ListFrame = props => {
  const {
    variant,
    expanded,
    expandHandler,
    summary,
    details,
    panelClass,
    summaryClass,
    detailsClass,
    iconExpandClass
  } = props;
  const isFormFill = variant === 'form-fill';
  const accordionIcon = propsVariant => {
    if (propsVariant === 'expand-list') return null;
    else return <ExpandMoreIcon className={iconExpandClass} />;
  };

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className={panelClass}
        expanded={expanded}
        onChange={expandHandler}
      >
        {isFormFill ? (
          <AccordionSummary
            className={summaryClass}
            expandIcon={<ExpandMoreIcon className={iconExpandClass} />}
          >
            {summary}
          </AccordionSummary>
        ) : (
          <AccordionSummary
            className={summaryClass}
            expandIcon={accordionIcon(variant)}
          >
            {summary}
          </AccordionSummary>
        )}
        <AccordionDetails className={detailsClass}>{details}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default ListFrame;
