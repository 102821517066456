import { greyishContainer } from '../../common/colors';

export default {
  root: {
    backgroundColor: greyishContainer,
    '&$disabled': {
      backgroundColor: greyishContainer
    },
    '&$focused': {
      backgroundColor: greyishContainer
    },
    '&:hover': {
      backgroundColor: greyishContainer,
      '@media (hover: none)': {
        backgroundColor: greyishContainer
      }
    },
    borderRadius: 4,
    minHeight: 56
  },
  focused: {},
  underline: {
    '&:after': {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4
    },
    '&:before': {
      borderBottom: '0px'
    },
    '&:hover': {
      '&:before': {
        borderBottom: '0px'
      }
    }
  },
  input: {
    paddingBottom: 0,
    paddingTop: 14
  },
  inputAdornedStart: {
    paddingTop: 0
  }
};
