import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getMinutes, getSecond } from 'helpers/Utility';

const CountdownCS = timers => {
  let timeLeft = 0;
  if (timers.timers.minutes > 1425) {
    timeLeft = (timers.timers.minutes - 1425) * 60;
  }

  const [timer, setTimer] = React.useState(timeLeft);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(prev => prev - 1);
      } else if (timer === 0) {
        localStorage.setItem('setCSFromFormFill', 0);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer, setTimer]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Paper
          style={{
            height: '64px',
            minWidth: '64px',
            left: '0px',
            top: '0px',
            backgroundColor: '#FEF5EE',
            padding: '12px'
          }}
        >
          <Grid alignItems="center" container direction="column">
            <Grid item>
              <Typography color="text.primary" variant="h4">
                {timer !== 0 && `${getMinutes(timer)}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.primary" variant="body2">
                Menit
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Paper
          style={{
            height: '64px',
            minWidth: '64px',
            left: '0px',
            top: '0px',
            backgroundColor: '#FEF5EE',
            padding: '12px'
          }}
        >
          <Grid alignItems="center" container direction="column">
            <Grid item>
              <Typography color="text.primary" variant="h4">
                {timer !== 0 && `${getSecond(timer)}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="text.primary" variant="body2">
                Detik
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

CountdownCS.propTypes = {
  activeBg: PropTypes.string,
  activeBorder: PropTypes.string,
  onclick: PropTypes.func,
  title: PropTypes.string
};

export default CountdownCS;
