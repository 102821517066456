import CryptoJS from 'crypto-js';
import jwt_decode from 'jwt-decode';
import { setCookie } from './saveToCookies';
import { CODE_RAYA_APPS, RAYA_APPS_DATA } from 'common/constants';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop)
});

const { token, deviceId, deviceType, deviceName, data } = params;

export const getDecryptJwtToken = (cookie = '') => {
  if (token || cookie) {
    const decodeParams = decodeURIComponent(token || cookie);
    const decryptedJwtToken = CryptoJS.AES.decrypt(
      decodeParams,
      process.env.REACT_APP_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);

    let decodedToken = '';

    if (token && !cookie) decodedToken = jwt_decode(decryptedJwtToken);
    else if (!token && cookie) decodedToken = JSON.parse(decryptedJwtToken);
    else if (token && cookie) decodedToken = jwt_decode(decryptedJwtToken);
    else return;

    return decodedToken;
  }
};

export const getDeviceInformation = (cookie = '') => {
  if ((deviceId && deviceType && deviceName) || cookie) {
    return {
      deviceId: deviceId || cookie?.deviceId || '',
      deviceType: deviceType || cookie?.deviceType || '',
      deviceName: deviceName || cookie?.deviceName || ''
    };
  }
};

export const getDecryptRayaAppsData = (cookie = '') => {
  if (token && !data) {
    localStorage.removeItem('isRayaAgreement');
    localStorage.removeItem('noHpRaya');
    localStorage.removeItem('noHpFlexiNew');

    setCookie(RAYA_APPS_DATA, '', 10);
    setCookie(CODE_RAYA_APPS, '', 10);
  } else {
    if (data || cookie) {
      let decodeData = '';
      let decodeAuthData = decodeURIComponent(data || cookie);
      const pattern = /\s/g;
      const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY_OTP);
      const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV_ENCRYPTION);
      const padding = CryptoJS.pad.Pkcs7;
      const mode = CryptoJS.mode.CBC;

      if (decodeAuthData.match(pattern))
        decodeData = decodeAuthData.replace(pattern, '+');
      else decodeData = decodeAuthData;

      const decryptedRayaAppsData = CryptoJS.AES.decrypt(
        decodeData,
        CryptoJS.SHA256(key),
        {
          iv: iv, // parse the IV
          padding: padding,
          mode: mode
        }
      ).toString(CryptoJS.enc.Utf8);

      const decryptData = JSON.parse(decryptedRayaAppsData);

      const rayaAppsData = {
        noHpFlexi: decryptData?.noHpFlexi || '',
        noHpRaya: decryptData?.noHpRaya || '',
        nikFlexi: decryptData?.nikFlexi || '',
        noHpFlexiNew: decryptData?.noHpFlexiNew || '',
        nikRaya: decryptData?.nikRaya || '',
        name: decryptData?.name || '',
        isRayaAgreement: decryptData?.isRayaAgreement || '',
        status: decryptData?.status || '',
        code: decryptData?.code || ''
      };

      return rayaAppsData;
    }
  }
};

export const setParamsDataRaya = () => {
  if (data) setCookie(RAYA_APPS_DATA, data, 10);
};
