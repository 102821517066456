import { neutralNr50 } from '../../common/colors';
import { primaryColorByPartner } from 'helpers/getColorPartner';

export default {
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)'
  },
  active: {
    '& $line': {
      backgroundColor: primaryColorByPartner,
      border: 'none'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: primaryColorByPartner,
      border: 'none'
    }
  },
  line: {
    height: 5,
    backgroundColor: neutralNr50,
    border: 'none',
    borderRadius: 2
  }
};
