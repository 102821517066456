export default theme => ({
  colorNotColumnFirst: {
    backgroundColor: theme.palette.common.neutralNr20
  },
  rootCell: {
    borderBottom: 'none'
  },
  fontCell: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px'
  },
  fontCellTwo: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px'
  }
});
