import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  frame: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: 16,
    borderRadius: '1rem 0rem 0rem 0rem'
  },
  frameTwo: {
    backgroundColor: theme.palette.common.primaryPr20,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: 16
  },
  frameThree: {
    backgroundColor: theme.palette.common.primaryPr20,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: 16,
    borderRadius: '0rem 1rem 0rem 0rem'
  },
  root: {
    flexGrow: 4
  },
  table: {
    minWidth: 'auto'
  }
}));
