import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './common/serviceWorker';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store/config';
import { CookiesProvider } from 'react-cookie';

const { store } = configureStore();

const app = (
  <CookiesProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
