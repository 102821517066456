import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { WithAppBar } from 'components';
import { getPartnerId } from 'helpers/getPartnerId';
import { getLogoByPartner } from 'helpers/getAssetsByPartner';

const imgUrlBottom = 'images/assets/Mask_Group.svg';

const styles = theme => ({
  appbar: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none'
  },
  appbarTransparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  appBarBottom: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${imgUrlBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: '100%',
    height: '200px',
    position: 'absolute',
    zIndex: '-1',
    top: '0',
    left: '0'
  },
  logo: {
    width: '100%',
    height: 'auto'
  },
  root: {
    margin: '2rem 1.5rem 0 1.5rem'
  },
  rootBar: {
    padding: theme.spacing(1)
  },
  buttonRiwayat: {
    borderColor: '#dedede',
    padding: '5px 10px'
  },
  notifPaper: {
    padding: '5px'
  },
  pinanglogo: {
    maxWidth: '72px',
    maxHeight: '32px',
    justifyContent: 'flex-start'
  }
});

const MainAppBar = props => {
  const { classes, variant, history, title } = props;
  let logo = '';
  switch (variant) {
    case 'withBackground':
      logo = (
        <img
          onClick={props.logoClicked}
          src="/images/assets/logo_pinang_putih.svg"
        />
      );
      break;
    default:
      logo = (
        <img onClick={props.logoClicked} src={getLogoByPartner(getPartnerId)} />
      );
  }

  const handleClass = val => {
    let end = null;
    switch (val) {
      case 'transparent':
        end = classes.appbarTransparent;
        break;
      case 'withBackground':
        end = classes.appbarTransparent;
        break;
      case 'backgrounBottom':
        end = classes.appBarBottom;
        break;
      default:
        end = classes.appbar;
        break;
    }
    return end;
  };
  return (
    <>
      {history && <WithAppBar history={history} title={title} />}
      <AppBar
        className={handleClass(variant)}
        position={props.static ? 'static' : 'fixed'}
      >
        <div
          className={
            variant === 'withBackground' ? classes.root : classes.rootBar
          }
        >
          <Grid className={classes.pinanglogo} container>
            {!history && (
              <Grid className={classes.pinanglogo} item>
                {logo}
              </Grid>
            )}
          </Grid>
        </div>
      </AppBar>
    </>
  );
};

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string
};

export default withStyles(styles)(MainAppBar);
