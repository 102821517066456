import React from 'react';
import { withStyles, Typography, Grid, makeStyles } from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { textSecondary } from 'common/colors';

const Panel = withStyles({
  root: {
    borderRadius: '6px',
    // border: '1px solid #219031',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    '&:last-child': {
      borderRadius: '6px'
    },
    '&:first-child': {
      borderRadius: '6px'
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const Summary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    alignItems: 'center'
  },
  expanded: {},
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(0deg)'
    }
  }
})(MuiExpansionPanelSummary);

const Details = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

const CustomizedExpansionPanels = props => {
  const {
    variant,
    expanded,
    expandHandler,
    summary,
    details,
    panelClass,
    summaryClass,
    detailsClass,
    type,
    isError,
    appStatus,
    isSecondApply
  } = props;

  const classes = styles();
  const isFormFill = variant === 'form-fill';

  const handleExpand = () => {
    if (variant === 'expand-list') return null;
    else return <ExpandMoreIcon />;
  };

  const showEditButton =
    type !== 'accountInformation' &&
    (appStatus === 'CON_SAVED' || isSecondApply === 'Y');

  const expandIcon = (
    <Grid
      alignItems="center"
      container
      direction="row"
      justify="center"
      spacing={1}
    >
      {showEditButton && (
        <Typography
          className={classes.textLink}
          color="secondary"
          onClick={props.action}
          style={{
            marginRight: 10
          }}
        >
          Ubah
        </Typography>
      )}

      {expanded ? (
        <ExpandLessIcon style={{ fill: textSecondary }} />
      ) : (
        <ExpandMoreIcon style={{ fill: textSecondary }} />
      )}
    </Grid>
  );

  return (
    <div className={classes.boxAlertInfo}>
      <Panel
        TransitionProps={{ unmountOnExit: true }}
        className={panelClass}
        expanded={expanded}
        onChange={expandHandler}
      >
        {isFormFill ? (
          <Summary className={summaryClass} expandIcon={expandIcon}>
            {summary}
          </Summary>
        ) : (
          <MuiExpansionPanelSummary
            className={summaryClass}
            expandIcon={handleExpand}
          >
            {summary}
          </MuiExpansionPanelSummary>
        )}
        <Details className={detailsClass}>{details}</Details>
      </Panel>
      {isError && (
        <div className={classes.alertInfo}>
          <Typography variant="caption">
            Yuk, segera lengkapi data yang belum kamu isi.
          </Typography>
        </div>
      )}
    </div>
  );
};

const styles = makeStyles(theme => ({
  textLink: {
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    fontSize: 12,
    textAlign: 'right',
    textDecoration: 'underline'
  },
  boxAlertInfo: {
    borderRadius: '8px',
    backgroundColor: theme.palette.common.neutralNr20
  },
  alertInfo: {
    padding: '8px',
    marginRight: '16px',
    marginLeft: '16px'
  }
}));

export default CustomizedExpansionPanels;
