import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { white } from 'common/colors';

const WarningDrawerContent = props => {
  const {
    srcImage,
    content,
    subContent,
    align,
    buttonOutlined = false,
    labelButtonOutlined,
    contentButton,
    clickWarning,
    textList
  } = props;

  const useStyles = makeStyles({
    rootContent: {},
    contentMessage: {
      marginBottom: '8px'
    },
    // subContentMessage: {
    // margixnBottom: '24px'
    // },
    buttonText: {
      font: 'Lato',
      fontWeight: 700,
      lineHeight: '24px',
      marginTop: '2vh',
      color: white
    },
    buttonOutlined: {
      borderRadius: '8px'
    },
    imageArea: {
      maxWidth: '65%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginBottom: '16px'
    }
  });

  const alignSubContent = () => {
    if (align) return align;
    else if (textList) return 'left';
    else return 'center';
  };

  const classes = useStyles();

  return (
    <div className={classes.rootContent}>
      {srcImage && (
        <img alt="warning" className={classes.imageArea} src={srcImage} />
      )}
      <Typography
        align={align ? align : 'center'}
        className={classes.contentMessage}
        color="textPrimary"
        gutterBottom
        variant="subtitle1"
      >
        {' '}
        {content}{' '}
      </Typography>
      {subContent && (
        <>
          <Typography
            align={alignSubContent()}
            // className={classes.subContentMessage}
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            {' '}
            {subContent}
          </Typography>
          {textList ? (
            <Typography
              align="left"
              // className={classes.subContentMessage}
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {textList}
            </Typography>
          ) : null}
        </>
      )}
      {buttonOutlined && (
        <Button
          className={classes.buttonOutlined}
          color="Secondary"
          fullWidth
          variant="outlined"
        >
          {' '}
          {labelButtonOutlined}{' '}
        </Button>
      )}
      {contentButton && (
        <Button
          className={classes.buttonText}
          color="secondary"
          fullWidth
          onClick={clickWarning}
          variant="contained"
        >
          {contentButton}
        </Button>
      )}
    </div>
  );
};

WarningDrawerContent.propTypes = {
  align: PropTypes.string,
  clickWarning: PropTypes.func,
  content: PropTypes.string,
  contentButton: PropTypes.string,
  srcImage: PropTypes.string,
  subContent: PropTypes.string
};

export default WarningDrawerContent;
