import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  messageData: []
};

const getNotificationUser = (state, action) => {
  return updateObject(state, { messageData: action.message });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATION_USER:
      return getNotificationUser(state, action);
    default:
      return state;
  }
};

export default reducer;
