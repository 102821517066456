import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  generateTokenRes: '',
  accessToken: '',
  verifySessionRes: '',
  customerAccessToken: '',
  cashInqRes: '',
  sessionId: '',
  enableDirectDebitRes: ''
  // partnerId: '',
};

const setAccessToken = (state, action) => {
  return updateObject(state, {
    generateTokenRes: action.res,
    accessToken: action.token
  });
};

const setVerifySessionResponse = (state, action) => {
  return updateObject(state, {
    verifySessionRes: action.res,
    customerAccessToken: action.token
  });
};

const setCashInquiryResponse = (state, action) => {
  return updateObject(state, { cashInqRes: action.res, sessionId: action.id });
};

const setEnableDirectDebitRes = (state, action) => {
  return updateObject(state, { enableDirectDebitRes: action.res });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCESS_TOKEN:
      return setAccessToken(state, action);
    case actionTypes.SET_VERIFY_SESSION_RES:
      return setVerifySessionResponse(state, action);
    case actionTypes.SET_CASH_INQUIRY_RES:
      return setCashInquiryResponse(state, action);
    case actionTypes.SET_DIRECT_DEBIT_RES:
      return setEnableDirectDebitRes(state, action);
    default:
      return state;
  }
};

export default reducer;
