import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import './style.module.css';
import {
  creditCardFormating
} from 'helpers/Utility';

const TransactionDetail = props => {
  const {
    isSuccess,
    cardNumber,
    custName,
    variantBank,
    isDashboard
  } = props;
  let content = '';
  let title = '';
  const useStyles = makeStyles(theme => ({
    paddingBox: {
      padding: '8px 16px 8px 16px'
    },
    redSmText: {
      fontFamily: 'Lato',
      fontWeight: 700,
      color: theme.palette.common.stateErrorRaya
    },
    footerText: {
      marginTop: '28px',
      marginBottom: '40px'
    },
    title: {
      lineHeight: '18px'
    },
    titleInfo: {
      fontFamily: 'Lato',
      padding: '5px 0',
      fontWeight: 700
    },
    subtitleInfo: {
      fontFamily: 'Lato',
      fontWeight: 700
    },
    container: {
      paddingBottom: '1rem',
      marginTop: '20px'
    },
    orangeSmText: {
      fontFamily: 'Lato',
      fontWeight: 700
    },
    buttonMargin: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();

  if (isSuccess) {
    title = (
      <>
        <Typography
          align="left"
          className={classes.title}
          color="textPrimary"
          variant="subtitle1"
        >
          Rekening Pencairan
        </Typography>
      </>
    );
    content = (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary" variant="subtitle2">
              Bank
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="textPrimary" variant="subtitle2">
              : Bank {variantBank}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary" variant="subtitle2">
              Nomor Rekening
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="textPrimary" variant="subtitle2">
              : {creditCardFormating(cardNumber)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary" variant="subtitle2">
              Nama
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="textPrimary" variant="subtitle2">
              : {custName}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  const informationLate = (propsIsdashboard, propsIsSuccess) => {
    if (propsIsSuccess) {
      return (
        <Typography
          className={classes.footerText}
          color="textPrimary"
          variant="body2"
        >
          Rekening diatas akan digunakan sebagai rekening pencairan pinjaman dan
          sebagai rekening pembayaran cicilan otomatis
        </Typography>
      );
    } else {
      if (propsIsdashboard) return '';
      else
        return (
          <Typography
            className={classes.footerText}
            color="textPrimary"
            variant="body2"
          >
            Keterlambatan pelunasan pinjaman akan dikenakan denda sebesar
            <strong> Jumlah hari keterlambatan * Biaya layanan * 0.5% </strong>
          </Typography>
        );
    }
  };

  return (
    <>
      <Container className={classes.container}>
        <div style={{ marginBottom: '1rem' }}>{title}</div>
        <div style={{ marginTop: '26px' }}>{content}</div>
        {informationLate(isDashboard, isSuccess)}
      </Container>
    </>
  );
};

TransactionDetail.propTypes = {
  cardNumber: PropTypes.string,
  custName: PropTypes.string,
  dashboardHandler: PropTypes.func,
  isDashboard: PropTypes.bool,
  isSuccess: PropTypes.bool,
  variantBank: PropTypes.string
};

export default TransactionDetail;
