import { PARTNER_ID_GENERAL } from 'common/config';
import { PARTNER_ID } from 'common/constants';
import { getCookie } from 'helpers/saveToCookies';
import { getDecryptJwtToken } from './getQueryStringUrl';

const getCookieOrUrlPartnerId = () => {
  if (getDecryptJwtToken()?.partnerId) return getDecryptJwtToken().partnerId;
  else if (getCookie(PARTNER_ID).length > 2) return getCookie(PARTNER_ID);
  else {
    return PARTNER_ID_GENERAL;
  }
};

export const getPartnerId = getCookieOrUrlPartnerId();
