import * as actionTypes from './actionTypes';
import axiosGolang from 'lib/axios/axios-golang';
import { USERS, HELP_TOPIC } from '../../common/config';
import { setError } from './auth';
import { loading } from './loanApply';

// Function for retirive data from API HELP_TOPIC
export const successHelp = dataFaq => {
  return {
    type: actionTypes.FETCH_HELP_SUCCESS,
    data: dataFaq
  };
};

// Function fro Fetching HELP_TOPIC from API
export const fetchingHelpTopic = (auth, param) => {
  const header = {
    headers: {
      Authorization: auth.token
    }
  };

  const url = USERS + HELP_TOPIC;
  return dispatch => {
    dispatch(loading(true));
    //begin to fecth FAQ help from API
    axiosGolang
      .get(url, header)
      .then(res => {
        res.data.data && dispatch(successHelp(res.data.data));
        param && param.action();
      })
      // if there is error when fetching the data
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};
