import { PARTNER_ID_BPJS, PARTNER_ID_BPJS_KASBON } from 'common/config';

export const getLogoByPartner = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/logo-pinang-flexi-dana-siaga.svg';
  } else {
    return '/images/assets/logo-pinang-flexi-blue-tosca.svg';
  }
};

export const getIconBackSpaceforPin = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/backspace_New.svg';
  } else {
    return '/images/assets/backspace_pinang.svg';
  }
};

export const getCardBackgroundDashboard = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return 'images/assets/disburse_dashboard_green.svg';
  } else {
    return 'images/assets/disburse_dashboard_blue.svg';
  }
};

export const getIconBottomNavBar = (partnerId, type) => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    switch (type) {
      case 'activeLoanIcon':
        return '/images/assets/pinjaman_new_green.svg';
      case 'activeProfileIcon':
        return '/images/assets/profile_new_green.svg';
      case 'activeHomeIcon':
        return '/images/assets/home_new_green.svg';
      case 'activeNotificationIcon':
        return '/images/assets/notification_new_green.svg';
      default:
        break;
    }
  } else {
    switch (type) {
      case 'activeLoanIcon':
        return '/images/assets/pinjaman_new_blue.svg';
      case 'activeProfileIcon':
        return '/images/assets/profile_new_blue.svg';
      case 'activeHomeIcon':
        return '/images/assets/home_new_blue.svg';
      case 'activeNotificationIcon':
        return '/images/assets/notification_new_blue.svg';
      default:
        break;
    }
  }
};

export const getDocumentLogoByPartner = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/document.svg';
  } else {
    return '/images/assets/document_blue.svg';
  }
};

export const getCameraIconByPartner = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/camera1.svg';
  } else {
    return '/images/assets/camera2.svg';
  }
};

export const getHomePageIcon = (partnerId, type) => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    switch (type) {
      case 'npwp':
        return '/images/assets/icon/npwp.svg';
      case 'interest':
        return '/images/assets/icon/interest.svg';
      case 'time':
        return '/images/assets/icon/time.svg';
      case 'e-ktp':
        return '/images/assets/icon/e-ktp.svg';
      default:
        break;
    }
  } else {
    switch (type) {
      case 'npwp':
        return '/images/assets/icon/npwp_blue.svg';
      case 'interest':
        return '/images/assets/icon/interest_blue.svg';
      case 'time':
        return '/images/assets/icon/time_blue.svg';
      case 'e-ktp':
        return '/images/assets/icon/e-ktp_blue.svg';
      default:
        break;
    }
  }
};

export const getWhiteLogoByPartner = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/logo_dana_siaga_white.svg';
  } else {
    return '/images/assets/logo_pinang_white_full.svg';
  }
};

export const getBackgroundHomePageByPartner = partnerId => {
  if (partnerId === PARTNER_ID_BPJS || partnerId === PARTNER_ID_BPJS_KASBON) {
    return '/images/assets/bg.svg';
  } else {
    return '/images/assets/bg_raya.svg';
  }
};
