import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Paper,
  withStyles,
  IconButton
} from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import styles from './styles';

const LoanBox = ({
  classes,
  title,
  img,
  description,
  action,
  onDrawerChange
}) => {
  const onDrawerAction = () => {
    onDrawerChange(action);
  };

  return (
    <>
      <Paper
        className={classes.paper}
        onClick={onDrawerAction}
        variant="outlined"
      >
        <div className={classes.rootGrid}>
          <Typography
            className={classes.subTitle}
            color="primary"
            variant="subtitle1"
          >
            {title}
          </Typography>
          <Grid container direction="row" spacing={1}>
            <Grid className={classes.media} item md={4} xs={4}>
              <img alt="" src={img} width="auto" />
            </Grid>
            <Grid item md={7} xs={7}>
              <Typography className={classes.descText} variant="caption">
                {description}
              </Typography>
            </Grid>
            <Grid className={classes.logo} item md={1} xs={1}>
              <IconButton>
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

LoanBox.propTypes = {
  action: PropTypes.string,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  img: PropTypes.string,
  onDrawerChange: PropTypes.func,
  title: PropTypes.string
};

export default withStyles(styles)(LoanBox);
