import React from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';

const Tooltips = props => {
  const { handleOpen, handleClose, open, title } = props;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        className
        PopperProps={{
          disablePortal: true
        }}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleClose}
        open={open}
        placement="top"
        title={title}
      >
        <img
          onClick={handleOpen}
          src="/images/assets/tooltip-icon.svg"
          style={{ marginLeft: '5.33px' }}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export default Tooltips;
