import { DEVICE_INFORMATION } from 'common/constants';
import { getCookie } from './saveToCookies';

export const getDeviceDetails = (phoneNumber, diff) => {
  const getDevice = getCookie(DEVICE_INFORMATION);
  const strToObj = getDevice ? JSON.parse(getDevice) : '';

  if (getDevice) {
    return {
      mobileNo: phoneNumber,
      deviceId: strToObj.deviceId,
      deviceName: strToObj.deviceName,
      deviceType: strToObj.deviceType
    };
  } else {
    return {
      mobileNo: phoneNumber,
      deviceId: diff.deviceId + phoneNumber,
      deviceName: diff.deviceName + phoneNumber,
      deviceType: diff.deviceType + phoneNumber
    };
  }
};
