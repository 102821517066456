import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../styles';

import { MessageBox, BannerNotif } from 'components';

const DisbursementBanner = ({ enabled, ...rest }) => {
  if (!enabled) return null;
  return (
    <MessageBox {...rest}>
      <BannerNotif primary />
    </MessageBox>
  );
};

export default withStyles(styles)(DisbursementBanner);
