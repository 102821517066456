import React from 'react';
import MainAppBar from '../../components/MainAppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const WithAppBar = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: props.noPadding ? 0 : theme.spacing(1)
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MainAppBar
        color={props.color}
        history={props.history}
        icon={props.icon}
        progressive={props.progressive}
        title={props.title}
        titleAlign={props.titleAlign}
        type={props.type}
        variant={props.variant}
      />
      {props.children}
    </div>
  );
};

export default WithAppBar;
