import React from 'react';

import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import clsx from 'clsx';
import styles from './styles';

const BodyTable = ({ bodyData, classes, isFirstIndex }) => {
  return (
    <>
      <TableCell
        align="left"
        className={clsx(
          !isFirstIndex && classes.colorNotColumnFirst,
          classes.rootCell,
          isFirstIndex > 0 ? classes.fontCell : classes.fontCellTwo
        )}
      >
        {bodyData}
      </TableCell>
    </>
  );
};

export default withStyles(styles)(BodyTable);
