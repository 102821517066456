const getMinutesFromSecond = second => Math.floor(second / 60);

const padSecond = second =>
  String(second).length === 1 ? `0${second}` : `${second}`;

export const getRemainingTimer = time => {
  const minutes = getMinutesFromSecond(time);

  const seconds = padSecond(time - minutes * 60);

  return { minutes, seconds };
};
