import { green } from 'common/colors';

export default {
  indicator: {
    height: '4px'
  },
  root: {
    backgroundColor: green
  }
};
