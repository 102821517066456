export default theme => ({
  resendOtpActive: {
    fontWeight: 'bolder',
    textDecoration: 'underline',
    color: theme.palette.primary.main
  },
  resendOtpInActive: {
    color: theme.palette.text.primary
  },
  warn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main,
    display: 'block',
    textAlign: 'center'
  },
  gridTimer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    columnGap: theme.spacing(2)
  }
});
