export default theme => ({
  title: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    color: theme.palette.common.brown,
    margin: '1vh 0 2vh 0'
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '12px',
    color: theme.palette.common.brown,
    margin: '2vh 0 2vh 0'
  },
  body: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: 'rgba(74,74,74,0.8)',
    whiteSpace: 'pre-line',
    margin: '1vh 0 1vh 0'
  },

  subTitleRiplay: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary
    // margin: '2vh 0 2vh 0'
  },
  bodyRiplay: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    margin: '1vh 0 1vh 0'
  },
  tableContainer: {
    border: `1px solid ${theme.palette.common.neutralNr30}`,
    borderRadius: '8px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableOutlined: {
    border: `1px solid ${theme.palette.common.neutralNr30}`,
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableCell: {
    border: `0px solid ${theme.palette.common.neutralNr30}`,
    padding: '4px 8px',
    width: '50%'
  },
  tableCellOutlined: {
    border: `1px solid ${theme.palette.common.neutralNr30}`,
    padding: theme.spacing(1),
    width: '50%'
  },
  titleTable: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
});
