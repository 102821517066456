import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { thousandFormat } from 'helpers/Utility';
import React from 'react';
const DrawerList = ({
  data,
  handleChangeValue,
  id,
  loanAmount,
  interestLoanInstallment
}) => {
  const primaryTextList = text => {
    if (id !== 'jobTitle' && id !== 'dailyNormalTransactions')
      return text.label;
    else return text.value;
  };

  const secondaryTextList = value => {
    if (id === 'tenure') {
      if (loanAmount > 0) {
        return (
          `Rp ${thousandFormat(
            Math.round(
              ((loanAmount / value + interestLoanInstallment + Number.EPSILON) *
                100) /
                100
            ),
            '.'
          )}` + ' per bulan'
        );
      } else {
        return '-';
      }
    } else return;
  };

  return (
    <>
      <List>
        {data.map((text, index) => (
          <div key={index}>
            <ListItem button key={index}>
              <ListItemText
                onClick={() => handleChangeValue(text)}
                primary={primaryTextList(text)}
                secondary={secondaryTextList(text.value)}
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 400,
                  fontSize: '14px',
                  borderRadius: '16px, 16px, 0px, 0px'
                }}
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </>
  );
};

export default DrawerList;
