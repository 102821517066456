import React from 'react';
import PropTypes from 'prop-types';

import { Divider, withStyles, Grid } from '@material-ui/core';

const styles = () => ({
  lineDashed: {
    background:
      'repeating-linear-gradient(to right,#939393 0px,#939393 8px,transparent 4px,transparent 14px)'
    // margin: '0rem -0.5rem'
  },
  circleLeft: {
    width: '10px',
    height: '10px',
    background: '#C4C4C4',
    borderRadius: '50%',
    position: 'relative',
    left: '-1.56%',
    right: '98.44%',
    top: '35.5%',
    bottom: '0%'
  },
  circleRight: {
    width: '10px',
    height: '10px',
    background: '#C4C4C4',
    borderRadius: '50%',
    position: 'relative',
    left: '98.44%',
    right: '-1.56%',
    top: '-43.5%',
    bottom: '0%'
  }
});

const DivederCard = ({ classes, kind }) => {
  return (
    <>
      {kind === 'lineDashed' && 
        (
          <div style={{ padding: '1px', height: '1rem' }}>
          {/* <div className={classes.circleLeft} /> */}
          <Divider className={classes.lineDashed} />
          {/* <div className={classes.circleRight} /> */}
        </div>
        )
      }

      {kind === 'solid' && 
        (
          <Grid style={{border: '1px solid #EBEDF0', marginBottom:'16px'}}/> 
        )
      }
    </> 
  );
};

DivederCard.propTypes = {
  classes: PropTypes.object,
  kind : PropTypes.string
};

DivederCard.defaultProps = {
  classes: {},
  kind : 'lineDashed'
};

export default withStyles(styles)(DivederCard);
