import { greyishContainer } from '../../common/colors';

export default {
  root: {
    // backgroundColor: greyishContainer
  },
  select: {
    '&:focus': {
      backgroundColor: greyishContainer
    },
    '&:before': {
      backgroundColor: greyishContainer
    },
    '&:after': {
      backgroundColor: greyishContainer
    }
  },
  filled: {
    paddingLeft: 0,
    paddingTop: 14
  }
  // inputAdornedStart: {
  //   paddingTop: 20
  // }
};
