import React from 'react';

import { ContentBoxInfo, MessageBox } from 'components';
import { checkMaintenanceBanner } from 'helpers/Utility';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const FlagBoxMaintenance = props => {
  const { flagBoxs, appStatus, classes } = props;

  const getMessageBox = flagBox => {
    if (checkMaintenanceBanner(flagBox.activatedOn, appStatus)) {
      if (flagBox.typeFlagBox === 'WARNING') {
        return (
          <MessageBox
            BorderOrange
            Orange
            className={classes.messageBoxMaintenance}
          >
            <ContentBoxInfo
              description={flagBox.descriptionFlagBox}
              title={flagBox.titleFlagBox}
              type={flagBox.typeFlagBox}
            />
          </MessageBox>
        );
      } else
        return (
          <MessageBox
            Blue
            BorderBrightBlue
            className={classes.messageBoxMaintenance}
          >
            <ContentBoxInfo
              description={flagBox.descriptionFlagBox}
              title={flagBox.titleFlagBox}
              type={flagBox.typeFlagBox}
            />
          </MessageBox>
        );
    } else return;
  };

  return <>{flagBoxs && flagBoxs.map(flagBox => getMessageBox(flagBox))}</>;
};

export default withStyles(styles)(FlagBoxMaintenance);
