import React from 'react';
import classes from './style.module.css';
import Grid from '@material-ui/core/Grid';

import clsx from 'clsx';

const Pin = props => {
  const white = <div className={clsx(classes.DotPinInput, classes.White)} />;

  const red = <div className={clsx(classes.DotPinInput, classes.Red)} />;

  const orange = <div className={clsx(classes.DotPinInput, classes.Orange)} />;

  const pinInputColor = (propsRed, propsDot) => {
    if(!propsRed){
      if(propsDot) return orange
      else return white
    }
    else return red
  }

  return (
    <div className={classes.Root}>
      <Grid
        className={classes.Container}
        container
        direction="row"
        justify="space-evenly"
      >
        {pinInputColor(props.red, props.orange.dot1)}
        {pinInputColor(props.red, props.orange.dot2)}
        {pinInputColor(props.red, props.orange.dot3)}
        {pinInputColor(props.red, props.orange.dot4)}
        {pinInputColor(props.red, props.orange.dot5)}
        {pinInputColor(props.red, props.orange.dot6)}
      </Grid>
    </div>
  );
};

export default Pin;
