import axios from 'axios';
import { BASE_URL } from '../../common/config';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 90000
  // headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Method': 'PUT, GET, POST, DELETE, OPTIONS',
  //     'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  //     'Access-Control-Allow-Credentials': 'true'
  // }
});

export default instance;
