import { orange, white } from 'common/colors';

import { colorByPartner, disableColorByPartner } from 'helpers/getColorPartner';

export default {
  root: {
    textTransform: 'none',
    fontSize: 15,
    padding: 12,
    borderRadius: 8,
    fontFamily: 'Lato',
    fontWeight: 700
  },
  outlined: {
    color: colorByPartner,
    backgroundColor: white,
    borderColor: colorByPartner,
    padding: 11
  },
  contained: {
    color: orange,
    backgroundColor: white,
    filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15))',
    boxShadow: 'inset -2px -2px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: colorByPartner
    },
    '&:disabled': {
      backgroundColor: disableColorByPartner,
      color: white
    }
  },
  containedPrimary: {
    backgroundColor: colorByPartner
  }
};
