import auth from './auth';
import loanApply from './loanApply';
import disburse from './disburse';
import history from './history';
import linkAja from './linkaja';
import forgotPin from './forgotPin';
import help from './help';
import notification from './notification';
import payment from './payment';

export default {
  auth,
  loanApply,
  disburse,
  history,
  linkAja,
  forgotPin,
  notification,
  help,
  payment
};
