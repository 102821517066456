import React from 'react';

import { DrawerInfo, WarningDrawerContent } from 'components';

const DrawerMaintenance = props => {
  const {
    tittleBottomSheet,
    descriptionBottomSheet,
    openBottomSheet,
    closeBottomSheet
  } = props;

  return (
    <DrawerInfo
      actionButtonLabel="Saya Mengerti"
      onActionHandler={closeBottomSheet}
      opened={openBottomSheet}
      setOpened={closeBottomSheet}
    >
      <WarningDrawerContent
        clickWarning={closeBottomSheet}
        content={tittleBottomSheet}
        srcImage="/images/assets/maintenance-img.svg"
        subContent={descriptionBottomSheet}
      />
    </DrawerInfo>
  );
};

export default DrawerMaintenance;
