import { textColorBottomNavBarbyPartner } from 'helpers/getColorPartner';

export default {
  root: {
    minWidth: 'calc(20%)',
    width: 'calc(20%)',
    padding: 0,
    '&$selected': {
      color: textColorBottomNavBarbyPartner,
      paddingTop: 0
    }
  }
};
