import React from 'react';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';

// MUI Core
import { Divider, Grid, Typography } from '@material-ui/core';

// Components
import { SurfaceGridInfo } from '../../components';

// Utility
import { rupiahFormat, creditCardFormating } from 'helpers/Utility';

import styles from './styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

const SurfaceGrid = props => {
  const {
    classes,
    className,
    loanAmount,
    bankCode,
    cardNumber,
    custName,
    loanTenur,
    feeAmount,
    isDisbursement,
    dueDate,
    tenorDaysOrMonth,
    ...rest
  } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true
    },
    className
  );

  const variant = bankCode === '494' ? 'RAYA' : 'BRI';

  const totalLoanAmount = Number(loanAmount) + Number(feeAmount);

  return (
    /* first base */
    <Grid className={rootClassName} {...rest} container direction="column">
      <Grid container item>
        <Grid container direction="row" item justify="space-between">
          <SurfaceGridInfo
            className={isDisbursement ? '' : classes.isDisbursementText}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            Jangka Waktu
          </SurfaceGridInfo>

          <SurfaceGridInfo
            className={isDisbursement ? '' : classes.isDisbursementText}
            color="textPrimary"
            gutterBottom
            topRight
            variant="body1"
          >
            <span className={classes.paperTextBold}>
              {tenorDaysOrMonth === 'D' ? loanTenur : 7} Hari
            </span>
          </SurfaceGridInfo>
        </Grid>
      </Grid>

      <Grid className={classes.divPaper} item style={{ height: '35px' }}>
        <Typography
          className={classes.successMessageBold2}
          color="textPrimary"
          variant="h5"
        >
          Rincian Tagihan Pinjaman
        </Typography>
      </Grid>

      <Grid className={classes.divPaper} container item>
        <Grid
          className={classes.infoHeight}
          container
          direction="row"
          item
          justify="space-between"
        >
          <SurfaceGridInfo color="textSecondary" variant="body1">
            Pokok Pinjaman
          </SurfaceGridInfo>

          <SurfaceGridInfo color="textPrimary" topRight variant="body1">
            <span className={classes.paperTextBold}>
              Rp{rupiahFormat.format(loanAmount)}
            </span>
          </SurfaceGridInfo>
        </Grid>

        <Grid
          className={classes.infoHeight}
          container
          direction="row"
          item
          justify="space-between"
        >
          <SurfaceGridInfo bottomLeft color="textSecondary" variant="body1">
            Biaya Layanan
          </SurfaceGridInfo>

          <SurfaceGridInfo bottomRight color="textPrimary" variant="body1">
            <span className={classes.paperTextBold}>
              Rp{rupiahFormat.format(feeAmount)}
            </span>
          </SurfaceGridInfo>
        </Grid>
      </Grid>

      <Grid className={classes.divPaper} item>
        <Divider className={classes.divider} />
      </Grid>

      <Grid className={classes.divPaper} container item>
        <Grid
          className={classes.infoHeight}
          container
          direction="row"
          item
          justify="space-between"
        >
          <SurfaceGridInfo bottomLeft color="textSecondary" variant="body1">
            Total Bayar
          </SurfaceGridInfo>

          <SurfaceGridInfo bottomRight variant="body1">
            <span className={classes.paperTextPriceBold}>
              Rp{rupiahFormat.format(totalLoanAmount)}
            </span>
          </SurfaceGridInfo>
        </Grid>
      </Grid>

      <Grid
        className={classes.grid3}
        container
        direction="column"
        item
        justify="center"
      >
        {isDisbursement ? (
          <>
            <Grid item>
              <SurfaceGridInfo color="textSecondary" smallMid variant="body1">
                Pencairan ke Bank {variant}
              </SurfaceGridInfo>
            </Grid>

            <Grid item>
              <SurfaceGridInfo className={classes.smallLatoDetailBold} smallMid>
                {creditCardFormating(cardNumber)}
              </SurfaceGridInfo>
            </Grid>

            <Grid item>
              <SurfaceGridInfo color="textSecondary" smallMid variant="body1">
                {custName}
              </SurfaceGridInfo>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <SurfaceGridInfo color="textSecondary" smallMid variant="body1">
                Penawaran pinjaman ini berlaku sampai
              </SurfaceGridInfo>
            </Grid>

            <Grid item>
              <SurfaceGridInfo className={classes.smallLatoDetailBold} smallMid>
                {moment(dueDate).format('DD MMM YYYY')}
              </SurfaceGridInfo>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

SurfaceGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(SurfaceGrid);
