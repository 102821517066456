import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  accountId: '',
  payments: [],
  loans: [],
  paymentDetail: {},
  loanDetail: {},
  loanInstallments: []
};

const setPayments = (state, action) => {
  return updateObject(state, { payments: action.payments });
};

const setLoans = (state, action) => {
  return updateObject(state, { loans: action.loans });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HISTORY_PAYMENTS:
      return setPayments(state, action);
    case actionTypes.SET_HISTORY_LOANS:
      return setLoans(state, action);
    default:
      return state;
  }
};

export default reducer;
