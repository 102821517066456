import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Grid, Typography, Box } from '@material-ui/core';
import { convertTime, maskingNumber } from 'helpers/Utility';

import styles from './styles';
import WithAppBar from 'components/withAppBar';
import {
  neutralNr40,
  secondarySc50,
  stateErrorRaya,
  textPrimary
} from 'common/colors';
import PinInput from 'react-pin-input';
import { DrawerInfo, PaperTimer, WithTransition } from 'components';
import { getRemainingTimer } from 'helpers/timer';

const OTP_HEADER = 'Cek kode verifikasi yang dikirim ke ';

const InputOTPComponent = ({
  classes,
  completedHandler,
  phoneNo,
  error,
  resendHandler,
  errorCount,
  timer,
  setTimer,
  resetError,
  history
}) => {
  const PIN_LENGTH = 6;
  const longerTimer = 60 * 10; /* 10 min */

  const [isDrawerFreeze, setIsDrawerFreeze] = React.useState(false);
  const [freezeTimer, setFreezeTimer] = React.useState(300);
  const [isFreeze, setIsFreeze] = React.useState(false);
  let pinInputRef = React.useRef();

  const pinStyle = {
    display: 'flex',
    justifyContent: 'space-evenly'
  };

  const focusStyle = {
    borderBottomColor:
      error === 'incorrect_otp' ? stateErrorRaya : secondarySc50
  };

  const inputStyle = {
    width: '45px',
    height: '65px',
    margin: '4px',
    border:
      error === 'incorrect_otp'
        ? `1px solid ${stateErrorRaya}`
        : `1px solid ${neutralNr40}`,
    borderBottomColor:
      error === 'incorrect_otp' ? stateErrorRaya : `1px solid ${neutralNr40}`,
    borderRadius: '8px',
    fontWeight: 'Bolder',
    fontFamily: 'Lato',
    color: textPrimary,
    fontSize: 20
  };

  const remainingFrezeTime = React.useCallback(getRemainingTimer(freezeTimer), [
    freezeTimer
  ]);

  React.useEffect(() => {
    if (errorCount === 3) {
      setTimeout(() => {
        setIsDrawerFreeze(prev => !prev);
        setIsFreeze(prev => !prev);
        setFreezeTimer(300);
      }, 300);
    } else if (errorCount >= 4) {
      setTimeout(() => {
        setIsDrawerFreeze(prev => !prev);
        setIsFreeze(prev => !prev);
        setFreezeTimer(longerTimer);
      }, 300);
    }
  }, [errorCount]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(prev => prev - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer, setTimer]);

  React.useEffect(() => {
    if (!isFreeze) return;

    const intervalFreeze = setInterval(() => {
      if (freezeTimer > 0) {
        setFreezeTimer(prev => prev - 1);
      } else {
        setIsFreeze(prev => !prev);
        resetError();
        setIsDrawerFreeze(false);
      }
    }, 1000);

    return () => {
      clearInterval(intervalFreeze);
    };
    // eslint-disable-next-line
  }, [freezeTimer, setFreezeTimer, isFreeze]);

  const onFreezeHandler = () => {
    setIsDrawerFreeze(prev => !prev);
  };

  const drawerFreezeOtp = (
    <DrawerInfo
      actionButtonLabel="Oke"
      onActionHandler={onFreezeHandler}
      opened={isDrawerFreeze}
      setOpened={setIsDrawerFreeze}
      title="Tunggu beberapa saat lagi"
    >
      <Typography color="textSecondary" gutterBottom variant="h6">
        Anda telah {errorCount} kali salah memasukan kode OTP. Coba lagi dalam{' '}
        {errorCount >= 4 ? 10 : 5} menit
      </Typography>
      <div className={classes.gridTimer}>
        <PaperTimer label="Menit" value={remainingFrezeTime.minutes} />
        <PaperTimer label="Detik" value={remainingFrezeTime.seconds} />
      </div>
    </DrawerInfo>
  );

  const changedHandler = value => {
    if (value.length === 5 && error) {
      pinInputRef.clear();
      resetError();
    }
  };

  return (
    <WithTransition>
      {drawerFreezeOtp}
      <WithAppBar history={history} noPadding variant="transparent">
        <Box style={{ padding: '12px' }}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            wrap="nowrap"
          >
            <Grid container direction="column" item spacing={1}>
              <Grid item>
                <Typography color="textPrimary" variant="h4">
                  Verifikasi nomor hp-mu dulu
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="h6">
                  {OTP_HEADER}
                  <strong>{maskingNumber(phoneNo)}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {isFreeze ? (
                <Typography align="center" color="textSecondary">
                  Anda telah {errorCount} kali salah memasukan kode OTP coba
                  lagi pada ({convertTime(freezeTimer)})
                </Typography>
              ) : (
                <>
                  <PinInput
                    initialValue=""
                    inputFocusStyle={focusStyle}
                    inputMode="number"
                    inputStyle={inputStyle}
                    length={PIN_LENGTH}
                    onChange={changedHandler}
                    onComplete={completedHandler}
                    ref={n => (pinInputRef = n)}
                    secret={false}
                    style={pinStyle}
                    type="numeric"
                  />
                  {error === 'incorrect_otp' && (
                    <Typography className={classes.warn} variant="caption">
                      Kode verifikasi salah, cek dan masukkan kembali.
                    </Typography>
                  )}
                  <Grid
                    container
                    direction="row"
                    item
                    justifyContent="center"
                    spacing={1}
                    style={{ marginTop: '8px' }}
                  >
                    <Grid item>
                      <Typography
                        align="center"
                        className={
                          timer === 0
                            ? classes.resendOtpActive
                            : classes.resendOtpInActive
                        }
                        onClick={resendHandler}
                        variant="h6"
                      >
                        Kirim ulang kode
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        align="center"
                        className={classes.subtitle}
                        color="textPrimary"
                        onClick={resendHandler}
                        variant="h6"
                      >
                        {timer !== 0 && '(' + convertTime(timer) + ')'}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </WithAppBar>
    </WithTransition>
  );
};

InputOTPComponent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handler: PropTypes.func,
  icon: PropTypes.object
};

export default withStyles(styles)(InputOTPComponent);
