import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  data: []
};

const fecthHelpSuccess = (state, action) => {
  return updateObject(state, { data: action.data });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HELP_SUCCESS:
      return fecthHelpSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
