import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { creditCardFormating } from 'helpers/Utility';

const BoxRekening = ({ customerName, cardNumber, loanType, bankCode }) => {
  const useStyles = makeStyles({
    paper: {
      borderRadius: '16px',
      boxShadow: '2px 2px 12px rgba(43, 170, 62, 0.15)',
      marginBottom: '2rem'
    },
    content: {
      padding: '10px 0'
    },
    subContent: {
      padding: '10px'
    },
    titleText: {
      fontWeight: '700',
      fontFamily: 'Lato',
      lineHeight: '24px'
    },
    cardText: {
      fontWeight: '400',
      fontFamily: 'Lato',
      lineHeight: '24px'
    }
  });

  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={3} variant="elevation">
      <Grid
        alignItems="center"
        className={classes.content}
        container
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
      >
        <Grid item>
          {bankCode === '494' ? (
            <img src="/images/assets/half_card_raya.svg" />
          ) : (
            <img src="/images/assets/half_card_bri.svg" />
          )}
        </Grid>
        <Grid
          alignItems="flex-start"
          className={classes.subContent}
          container
          direction="column"
          justify="center"
        >
          <Grid item>
            <Typography
              className={classes.titleText}
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {loanType}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.cardText} variant="h5">
              {creditCardFormating(cardNumber)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              {' '}
              {customerName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BoxRekening.propTypes = {
  bankCode: PropTypes.number,
  cardNumber: PropTypes.string,
  customerName: PropTypes.string,
  loanType: PropTypes.string
};

export default BoxRekening;
