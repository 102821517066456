import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  creditScoringTimer: { minutes: 0, seconds: 0 },
  isCounting: false,
  creditScoreResult: 'init',
  offering1: '',
  offering2: '',
  selectedOffering: localStorage.getItem('selectedOffering')
    ? JSON.parse(localStorage.getItem('selectedOffering'))
    : '',
  loanAmountSelected: '',
  selfiePrivyImg1: localStorage.getItem('selfiePrivyImg1')
    ? localStorage.getItem('selfiePrivyImg1')
    : '',
  selfiePrivyImg2: localStorage.getItem('selfiePrivyImg2')
    ? localStorage.getItem('selfiePrivyImg2')
    : '',
  privyTnC: '',
  privyRegData: '',
  esignRegData: '',
  esignStatus: '',
  pinangDoc1: '',
  pinangDoc2: '',
  pinangDoc3: '',
  privyToken: '',
  selfieFinish1: '',
  selfieFinish2: '',
  privyValidRes: '',
  createLoanRes: '',
  isSuccessDisburse: true,
  elementStatus: '',
  selfieCount: 0,
  applicationDocs: '',
  passiveLivenessImage: localStorage.getItem('passiveLivenessImage')
    ? localStorage.getItem('passiveLivenessImage')
    : '',
  passiveLivenessImageVerif: '',
  ekycKey: localStorage.getItem('ekycKey')
    ? localStorage.getItem('ekycKey')
    : '',
  ekycStatus: '',
  registrationStatus: localStorage.getItem('registrationStatus')
    ? localStorage.getItem('registrationStatus')
    : '',
  registrationStatusIncoming: localStorage.getItem('registrationStatusIncoming')
    ? localStorage.getItem('registrationStatusIncoming')
    : '',
  rayaAccountName: '',
  rayaAccountNumber: '',
  briAccountNumber: '',
  urlRaya: ''
};

const takeSelfiePrivy = (state, action) => {
  localStorage.setItem('selfiePrivyImg1', action.pic1);
  localStorage.setItem('selfiePrivyImg2', action.pic2);

  return updateObject(state, {
    selfiePrivyImg1: action.pic1,
    selfiePrivyImg2: action.pic2,
    elementStatus: action.status
  });
};

const elementSelfie = (state, action) => {
  return updateObject(state, {
    selfiePrivyImg1: action.pic1,
    selfiePrivyImg2: action.pic2,
    elementStatus: action.status
  });
};

const timeoutSelfie = (state, action) => {
  return updateObject(state, { timeoutSelfie: action.state });
};

const takeSelfieFinish = (state, action) => {
  return updateObject(state, {
    selfieFinish1: action.pic1,
    selfieFinish2: action.pic2,
    elementStatus: action.status
  });
};

const setTimer = (state, action) => {
  return updateObject(state, { creditScoringTimer: action.timer });
};

const startTimer = state => {
  return updateObject(state, { isCounting: true });
};

const decMinutes = state => {
  return updateObject(state, {
    creditScoringTimer: updateObject(state.creditScoringTimer, {
      minutes: state.creditScoringTimer.minutes - 1
    })
  });
};

const decSeconds = state => {
  return updateObject(state, {
    creditScoringTimer: updateObject(state.creditScoringTimer, {
      seconds:
        state.creditScoringTimer.seconds > 0
          ? state.creditScoringTimer.seconds - 1
          : 59
    })
  });
};

const stopTimer = state => {
  return updateObject(state, { isCounting: false });
};

const setCreditScoreResult = (state, action) => {
  // if first time load or init and application status is CR_SCORE_SUB, then start the timer immedietly
  // to do that, we must set the credit score result to ''
  const result =
    state.creditScoreResult === 'init' && action.result === 'wait-longer'
      ? ''
      : action.result;
  return updateObject(state, { creditScoreResult: result });
};

const setOffering1 = (state, action) => {
  return updateObject(state, { offering1: action.offering });
};

const setOffering2 = (state, action) => {
  return updateObject(state, { offering2: action.offering });
};

const setSelectedOffering = (state, action) => {
  localStorage.setItem('selectedOffering', JSON.stringify(action.offering));
  return updateObject(state, {
    selectedOffering: action.offering,
    loanAmountSelected: action.offering.plafond
  });
};

const setloanAmountSelected = (state, action) => {
  return updateObject(state, { loanAmountSelected: action.amount });
};

const setPrivyTnC = (state, action) => {
  return updateObject(state, { privyTnC: action.tnc });
};

const setPrivyRegData = (state, action) => {
  return updateObject(state, { privyRegData: action.data });
};

const setEsignRegData = (state, action) => {
  return updateObject(state, { esignRegData: action.data });
};

const setEkycVendor = (state, action) => {
  return updateObject(state, { ekycVendor: action.data });
};

const setValidatePhotoSignature = (state, action) => {
  return updateObject(state, { validatePhotoRes: action.res });
};

const setEsignStatus = (state, action) => {
  return updateObject(state, { esignStatus: action.res });
};

const setPinangDoc1 = (state, action) => {
  return updateObject(state, { pinangDoc1: action.doc });
};

const setPinangDoc2 = (state, action) => {
  return updateObject(state, { pinangDoc2: action.doc });
};

const setPinangDoc3 = (state, action) => {
  return updateObject(state, { pinangDoc3: action.doc });
};

const setPinangDoc4 = (state, action) => {
  return updateObject(state, { pinangDoc4: action.doc });
};

const setPrivyToken = (state, action) => {
  return updateObject(state, { privyToken: action.token });
};

const setPrivyValidRes = (state, action) => {
  return updateObject(state, { privyValidRes: action.res });
};

const setCreateLoanRes = (state, action) => {
  return updateObject(state, { createLoanRes: action.res });
};

const countingTakePicture = state => {
  return updateObject(state, { selfieCount: state.selfieCount + 1 });
};

const resetTakePicture = state => {
  return updateObject(state, { selfieCount: 0 });
};

const setApplicationDoc = (state, action) => {
  return updateObject(state, { applicationDocs: action.doc });
};

const setPassiveLivenessImage = (state, action) => {
  localStorage.setItem('passiveLivenessImage', action.image);
  return updateObject(state, { passiveLivenessImage: action.image });
};

const setPassiveLivenessImageVerif = (state, action) => {
  return updateObject(state, { passiveLivenessImageVerif: action.image });
};

const setEkyKey = (state, action) => {
  localStorage.setItem('ekycKey', action.data);
  return updateObject(state, { ekycKey: action.data });
};

const setEkycStatus = (state, action) => {
  return updateObject(state, { ekycStatus: action.data });
};

const setRegistrationStatus = (state, action) => {
  localStorage.setItem('registrationStatus', action.registrationStatus);
  return updateObject(state, { registrationStatus: action.registrationStatus });
};

const setRegistrationStatusIncoming = (state, action) => {
  localStorage.setItem(
    'registrationStatusIncoming',
    action.registrationStatusIncoming
  );
  return updateObject(state, {
    registrationStatusIncoming: action.registrationStatusIncoming
  });
};

const setRayaAccountNumber = (state, action) => {
  return updateObject(state, { rayaAccountNumber: action.rayaAccountNumber });
};

const setBriAccountNumber = (state, action) => {
  return updateObject(state, { briAccountNumber: action.briAccountNumber });
};

const setRayaAccountName = (state, action) => {
  return updateObject(state, { rayaAccountName: action.rayaAccountName });
};

const setRayaCif = (state, action) => {
  return updateObject(state, { rayaCif: action.rayaCif });
};

const setUrlRaya = (state, action) => {
  return updateObject(state, { urlRaya: action.urlRaya });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TAKE_SELFIE_PRIVY:
      return takeSelfiePrivy(state, action);
    case actionTypes.TAKE_SELFIE_FINISH:
      return takeSelfieFinish(state, action);
    case actionTypes.SET_TIMER:
      return setTimer(state, action);
    case actionTypes.START_TIMER:
      return startTimer(state);
    case actionTypes.DEC_MINUTES:
      return decMinutes(state);
    case actionTypes.DEC_SECONDS:
      return decSeconds(state);
    case actionTypes.STOP_TIMER:
      return stopTimer(state);
    case actionTypes.SET_CS_RESULT:
      return setCreditScoreResult(state, action);
    case actionTypes.SET_OFFERING_1:
      return setOffering1(state, action);
    case actionTypes.SET_OFFERING_2:
      return setOffering2(state, action);
    case actionTypes.SET_SELECTED_OFFERING:
      return setSelectedOffering(state, action);
    case actionTypes.SET_LOAN_AMOUNT_SELECETED:
      return setloanAmountSelected(state, action);
    case actionTypes.SET_PRIVY_TNC:
      return setPrivyTnC(state, action);
    case actionTypes.SET_PRIVY_REG_DATA:
      return setPrivyRegData(state, action);
    case actionTypes.SET_ESIGN_REG_DATA:
      return setEsignRegData(state, action);
    case actionTypes.SET_EKYC_VENDOR:
      return setEkycVendor(state, action);
    case actionTypes.SET_VALIDATE_PHOTO_SIGNATURE:
      return setValidatePhotoSignature(state, action);
    case actionTypes.SET_ESIGN_STATUS:
      return setEsignStatus(state, action);
    case actionTypes.SET_PINANG_DOC_1:
      return setPinangDoc1(state, action);
    case actionTypes.SET_PINANG_DOC_2:
      return setPinangDoc2(state, action);
    case actionTypes.SET_PINANG_DOC_3:
      return setPinangDoc3(state, action);
    case actionTypes.SET_PINANG_DOC_4:
      return setPinangDoc4(state, action);
    case actionTypes.SET_PRIVY_TOKEN:
      return setPrivyToken(state, action);
    case actionTypes.SET_PRIVY_VALID_RES:
      return setPrivyValidRes(state, action);
    case actionTypes.SET_CREATE_LOAN_RES:
      return setCreateLoanRes(state, action);
    case actionTypes.TAKE_SELFIE_ELEMENT:
      return elementSelfie(state, action);
    case actionTypes.TIMEOUT_SELFIE:
      return timeoutSelfie(state, action);
    case actionTypes.COUNT_TAKE_PICTURE:
      return countingTakePicture(state);
    case actionTypes.RESET_TAKE_PICTURE:
      return resetTakePicture(state);
    case actionTypes.SET_APPLICATION_DOC:
      return setApplicationDoc(state, action);
    case actionTypes.SET_PASSIVE_LIVENESS_IMAGE:
      return setPassiveLivenessImage(state, action);
    case actionTypes.SET_PASSIVE_LIVENESS_IMAGE_VERIF:
      return setPassiveLivenessImageVerif(state, action);
    case actionTypes.SET_EKYC_KEY:
      return setEkyKey(state, action);
    case actionTypes.SET_EKYC_STATUS:
      return setEkycStatus(state, action);
    case actionTypes.SET_REGISTRATION_STATUS:
      return setRegistrationStatus(state, action);
    case actionTypes.SET_REGISTRATION_STATUS_INCOMING:
      return setRegistrationStatusIncoming(state, action);
    case actionTypes.SET_RAYA_ACCOUNT_NUMBER:
      return setRayaAccountNumber(state, action);
    case actionTypes.SET_BRI_ACCOUNT_NUMBER:
      return setBriAccountNumber(state, action);
    case actionTypes.SET_RAYA_ACCOUNT_NAME:
      return setRayaAccountName(state, action);
    case actionTypes.SET_RAYA_CIF:
      return setRayaCif(state, action);
    case actionTypes.SET_URL_RAYA:
      return setUrlRaya(state, action);
    default:
      return state;
  }
};

export default reducer;
