import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

// Shared components
import Paper from '../Paper';

import styles from './styles';

const StatusBox = ({
  classes,
  children,
  danger,
  warning,
  success,
  info,
  pending,
  fit,
  notice,
  primary,
  secondary,
  inline,
  center
}) => {
  const rootClassName = classNames(
    {
      [classes.isSuccess]: success,
      [classes.isDanger]: danger,
      [classes.isWarning]: warning,
      [classes.isPending]: pending,
      [classes.isFit]: fit,
      [classes.isNotice]: notice,
      [classes.isPrimary]: primary,
      [classes.isSecondary]: secondary,
      [classes.isInline]: inline
    },
    classes.root
  );

  const textClassName = classNames(
    {
      [classes.infoText]: info,
      [classes.noticeText]: notice,
      [classes.primaryText]: primary,
      [classes.secondaryText]: secondary
    },
    classes.text
  );
  return (
    <Paper
      className={rootClassName}
      elevation={0}
      outlined={false}
      squared={false}
    >
      <Typography
        align={center && 'center'}
        className={pending ? classes.pendingText : textClassName}
        variant="subtitle2"
      >
        {children}
      </Typography>
    </Paper>
  );
};

StatusBox.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  danger: PropTypes.bool,
  fit: PropTypes.bool,
  info: PropTypes.bool,
  inline: PropTypes.bool,
  notice: PropTypes.bool,
  pending: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool
};

StatusBox.defaultProps = {
  danger: false,
  fit: false,
  info: false,
  inline: false,
  notice: false,
  pending: false,
  primary: false,
  secondary: false,
  success: false,
  warning: false
};

export default withStyles(styles)(StatusBox);
