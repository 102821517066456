// Material helpers
import { createMuiTheme } from '@material-ui/core';

import typography from './typography';
import overrides from './overrides';
import palettes from './palettes';
import { getPartnerId } from 'helpers/getPartnerId';
import paletteGeneral from './palettes/paletteGeneral';

const theme = createMuiTheme({
  palette: palettes[getPartnerId] ?? paletteGeneral,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
