import { Box, FormControl, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
const SearchField = ({
  placeholder,
  dataList,
  setDataList,
  setInputText,
  withoutOther
}) => {
  const handleChange = e => {
    const value = e.target.value;
    let regex = new RegExp(value, 'i');

    let filtered = dataList?.filter(item => regex.test(item.label));

    setInputText(value);

    if (filtered?.length === 0 && value !== '') {
      if (!withoutOther) setDataList([{ value: 'Lainnya', label: 'Lainnya' }]);
      else setDataList([]);

      return;
    }
    if (value === '') {
      setDataList(dataList);
      return;
    }
    setDataList(filtered);
  };

  return (
    <Box style={{ width: '100%' }}>
      <FormControl style={{ width: '100%' }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          autoComplete="off"
          onChange={e => handleChange(e)}
          placeholder={placeholder}
          variant="outlined"
        />
      </FormControl>
    </Box>
  );
};

export default SearchField;
