import {
  PARTNER_ID_BPJS,
  PARTNER_ID_GENERAL,
  PARTNER_ID_BPJS_KASBON,
  PARTNER_ID_WEBVIEW_APK,
  PARTNER_ID_GENERAL_KASBON,
  PARTNER_ID_RBONOSAVING
} from 'common/config';
import paletteGeneral from './paletteGeneral';
import palettesBpjs from './palettesBpjs';

const palettes = {
  [PARTNER_ID_BPJS]: palettesBpjs,
  [PARTNER_ID_BPJS_KASBON]: palettesBpjs,
  [PARTNER_ID_GENERAL]: paletteGeneral,
  [PARTNER_ID_WEBVIEW_APK]: paletteGeneral,
  [PARTNER_ID_GENERAL_KASBON]: paletteGeneral,
  [PARTNER_ID_RBONOSAVING]: paletteGeneral
};

export default palettes;
