import { Button, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './style';
import { getPartnerId } from 'helpers/getPartnerId';
import { getLogoByPartner } from 'helpers/getAssetsByPartner';

const ConfirmationPage = props => {
  const { image, title, subTitle, action, buttonMsg, isDisable, classes } =
    props;
  const handleButton = () => {
    action && action();
  };
  return (
    <Container style={{ marginTop: '32px' }}>
      <Grid container direction="column" justify="space-between">
        <Grid item>
          <img src={getLogoByPartner(getPartnerId)} />
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="column"
          spacing={2}
          style={{ marginTop: '32px' }}
        >
          <Grid item>
            <img
              alt="icon-confirmation"
              className={classes.image}
              src={image}
            />
          </Grid>
          <Grid item>{title}</Grid>
          <Grid item>{subTitle}</Grid>
        </Grid>
        <Container className={classes.footer} style={{ marginBottom: '20px' }}>
          <Button
            color="secondary"
            disabled={isDisable}
            fullWidth
            onClick={handleButton}
            variant="contained"
          >
            {buttonMsg}
          </Button>
        </Container>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ConfirmationPage);
