import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/Utility';

const initialState = {
  forgotPinInputOtpRequestBody: {},
  reTypeSignOnPassword: '',
  signOnPassword: '',
  isNewPinAlreadyUsed: false
};

const setForgotPinNewPinAlreadyUsed = (state, action) => {
  return updateObject(state, {
    isNewPinAlreadyUsed: action.isNewPinAlreadyUsed
  });
};

const setReTypeSignOnPassword = (state, action) => {
  return updateObject(state, {
    reTypeSignOnPassword: action.reTypeSignOnPassword
  });
};

const setSignOnPassword = (state, action) => {
  return updateObject(state, {
    signOnPassword: action.signOnPassword
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORGOT_PIN_NEW_PIN_ALREADY_USED:
      return setForgotPinNewPinAlreadyUsed(state, action);
    case actionTypes.SET_RETYPE_SIGN_ON_PIN:
      return setReTypeSignOnPassword(state, action);
    case actionTypes.SET_TYPE_SIGN_ON_PIN:
      return setSignOnPassword(state, action);
    default:
      return state;
  }
};

export default reducer;
