export default theme => ({
  image: {
    textAlign: 'center',
    width: '200px',
    height: '150px'
  },
  title: {
    textAlign: 'center',
    color: theme.palette.common.green,
    fontWeight: 500,
    fontSize: '24px',
    fontFamily: 'Roboto',
    lineHeight: '32px'
  },
  subTitle: {
    textAlign: 'center',
    color: '#444444',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'Roboto'
  },
  topImg: {
    height: '32px',
    width: '72px',
    marginBottom: '32px'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: '500px',
    maxHeight: '100%',
    margin: '0 auto'
  }
});
