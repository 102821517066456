import { textPrimary } from 'common/colors';

export default {
  root: {
    color: textPrimary,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  }
};
