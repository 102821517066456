import { white, textSecondary, neutralNr50 } from 'common/colors';

export default {
  root: {
    backgroundColor: white,
    color: textSecondary,
    '&:hover': {
      backgroundColor: neutralNr50
    }
  }
};
