import React, { Component } from 'react';

// MUI Components
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  LinearProgress
} from '@material-ui/core';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';
import { secondarySc50 } from 'common/colors';

const styles = theme => ({
  appbar: {
    boxShadow: 'none',
    maxWidth: '500px',
    margin: '0 auto',
    right: 'auto'
  },
  appbarTransparent: {
    boxShadow: 'none',
    maxWidth: '500px',
    margin: '0 auto',
    right: 'auto',
    backgroundColor: 'transparent'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary
  },
  menuButtonTransparant: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    color: 'white',
    fontFamily: 'Lato',
    lineHeight: '24px'
  },
  toolbar: {
    width: '100%'
  },
  progressiveBar: {
    borderRadius: 0,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: secondarySc50
    }
  }
});

class MainAppBar extends Component {
  state = {
    percent: 0
  };

  onBack(history) {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login'
    ) {
      if (window.flutter_inappwebview) {
        history.goForward();
        window.flutter_inappwebview.callHandler('exitWebview', []);
      } else {
        history.goForward();
      }
    } else history.goBack();
  }

  componentDidMount() {
    const { progressive } = this.props;

    switch (progressive) {
      case 'persaved':
        this.setState({ percent: 33.3 });
        break;
      case 'paysaved':
        this.setState({ percent: 66.6 });
        break;
      case 'consaved':
        this.setState({ percent: 100 });
        break;
      default:
        this.setState({ percent: 0 });
        break;
    }
  }

  render() {
    const {
      classes,
      title,
      icon,
      onClick,
      history,
      variant,
      titleAlign,
      color,
      type
    } = this.props;

    const { percent } = this.state;

    return (
      <>
        <AppBar
          className={
            variant === 'transparent'
              ? classes.appbarTransparent
              : classes.appbar
          }
        >
          <Toolbar className={classes.toolbar}>
            {icon !== 'hide' && (
              <IconButton
                aria-label="menu"
                className={
                  variant === 'transparent'
                    ? classes.menuButton
                    : classes.menuButtonTransparant
                }
                color={variant === 'transparent' ? 'default' : 'inherit'}
                edge="start"
                onClick={onClick || this.onBack.bind(this, history)}
              >
                {icon}
              </IconButton>
            )}
            <Typography
              className={classes.title}
              style={{
                textAlign: titleAlign || 'center',
                color: color ? 'black' : 'white',
                paddingRight: '30'
              }}
              variant="h5"
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />
        {type === 'form' ? (
          <div style={{ height: '10px', margin: '0 auto' }}>
            <LinearProgress
              className={classes.progressiveBar}
              value={percent}
              variant="determinate"
            />
          </div>
        ) : null}
      </>
    );
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  titleAlign: PropTypes.oneOf(['left', 'center']),
  variant: PropTypes.string
};

MainAppBar.defaultProps = {
  title: '',
  icon: <ArrowBackIcon />,
  titleAlign: 'left'
};

export default withStyles(styles)(MainAppBar);
