import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { neutralNr20, neutralNr30 } from 'common/colors';
import { bgColorByPartnerId, colorByPartner } from 'helpers/getColorPartner';

const ButtonRefuseOffering = ({ title, isActive, onclick, index, value }) => {
  const handleOnclick = () => {
    onclick(index, value);
  };
  let activeBg = '';
  let activeBorder = '';

  if (isActive) {
    activeBg = bgColorByPartnerId;
    activeBorder = colorByPartner;
  } else {
    activeBg = neutralNr20;
    activeBorder = neutralNr30;
  }

  return (
    <Button
      onClick={handleOnclick}
      style={{
        background: activeBg,
        border: `1px solid ${activeBorder}`,
        borderRadius: '8px',
        padding: '12px 16px'
      }}
    >
      <Typography>{title}</Typography>
    </Button>
  );
};

ButtonRefuseOffering.propTypes = {
  activeBg: PropTypes.string,
  activeBorder: PropTypes.string,
  onclick: PropTypes.func,
  title: PropTypes.string
};

export default ButtonRefuseOffering;
