import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

const ContentBoxInfo = ({ title = '', description, type }) => {
  const classes = useStyles();

  const getIcon = () => {
    if (type === 'WARNING') {
      return '/images/assets/warning-icon.svg';
    } else {
      return '/images/assets/info-icon.svg';
    }
  };

  return (
    <Grid
      alignItems="center"
      className={classes.paddingBox}
      container
      direction="row"
      justify="space-between"
    >
      <Grid item>
        <img src={getIcon()} />
      </Grid>
      <Grid item xs={11}>
        <Grid container direction="column">
          <Grid item>
            <Typography color="textPrimary" variant="body2">
              <b>{title}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContentBoxInfo.defaultProps = {
  type: 'INFO'
};
export default ContentBoxInfo;
