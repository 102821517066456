import React from 'react';
import PropTypes from 'prop-types';

// Material UI component
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Custom component
import Paper from '../Paper';

// Component styles
const styles = theme => {
  return {
    root: {
      borderRadius: '12px',
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
      maxWidth: '64px',
      minWidth: '64px',
      minHeight: '64px',
      maxHeight: '64px',
      position: 'relative'
    },
    timerBox: {
      position: 'absolute',
      height: '100%',
      margin: 'auto'
    },
    value: {
      color: theme.palette.common.black,
      fontFamily: 'Lato',
      fontWeight: 'Bold'
    },
    label: {
      fontFamily: 'Lato'
    }
  };
};

const PaperTimer = ({ classes, value, label }) => {
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid
        alignItems="center"
        className={classes.timerBox}
        container
        direction="column"
        justify="center"
        wrap="nowrap"
      >
        <Grid item>
          <Typography align="center" className={classes.value} variant="h4">
            {value}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            align="center"
            className={classes.label}
            color="textPrimary"
            variant="caption"
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

PaperTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.number
};

export default withStyles(styles)(PaperTimer);
