import { white } from 'common/colors';
import { primaryColorByPartner } from 'helpers/getColorPartner';

export default {
  toolbarBtnSelected: {
    backgroundColor: primaryColorByPartner,
    fontSize: '34px',
    fontFamily: 'Roboto',
    lineHeight: '42px'
  },
  toolbarTxt: {
    color: white,
    fontSize: '14px'
  }
};
