import { stateErrorRaya, stateInfoRaya } from 'common/colors';

export default {
  root: {
    fontSize: '22px'
  },
  colorAction: {
    color: stateInfoRaya
  },
  colorError: {
    color: stateErrorRaya
  }
};
