import React from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import styles from './styles';

const BannerNotif = props => {
  const {
    classes,
    history,
    primary,
    secondary,
    path,
    title,
    announcement,
    historyText,
    value
  } = props;

  const pushHistory = () => {
    history.push({
      pathname: `${path}`
    });
  };

  return (
    <Grid
      alignItems="center"
      className={classes.bannerNotif}
      container
      direction="row"
      wrap="nowrap"
    >
      {primary && (
        <Grid className={classes.bannerNotifIcon} item xs={1}>
          <InfoIcon color="primary" />
        </Grid>
      )}
      {secondary && (
        <Grid className={classes.bannerNotifIcon} item xs={1}>
          <InfoIcon color="secondary" />
        </Grid>
      )}
      <Grid container direction="column" item xs={11}>
        <Grid container direction="column">
          <Typography
            className={classes.captionBold}
            color="textPrimary"
            variant="caption"
          >
            {title} {value}
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Typography
            color="textSecondary"
            onClick={pushHistory}
            variant="caption"
          >
            {announcement}
          </Typography>
        </Grid>
        <Grid direction="column">
          {history && (
            <Typography
              className={classes.captionBold}
              color="primary"
              onClick={pushHistory}
              variant="caption"
            >
              {historyText}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BannerNotif);
