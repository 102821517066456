export default theme => ({
  menu: {
    borderRadius: '12px',
    padding: '4px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.pinangBlack,
    fontWeight: 'normal',
    lineHeight: '24px'
  }
});
